import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  CircularProgress,
  Alert,
} from "@mui/material";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";

const formatDate = (date) => {
  if (!date) return "-"; // Return "-" if date is null or undefined
  const parsedDate = new Date(date);
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const year = parsedDate.getFullYear();
  return `${day}/${month}/${year}`;
};

const Competencies = () => { 
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = sessionStorage.getItem("loggedinUserId");

  useEffect(() => {
    const fetchPolicies = async () => {
      try {
        const response = await axiosInstance.get(
          ApiUrls.ASSIGNMENT_API + "/By/userId"
        );
        setPolicies(response.data);
        setLoading(false);
      } catch (err) {
        setError("An error occurred while fetching the policies.");
        setLoading(false);
      }
    };

    fetchPolicies();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <div>
      <Typography variant="h4" sx={{ fontWeight: "bold", flex: 1, mt: 1 }}>
        Competencies
      </Typography>

      <Paper
        sx={{
          borderRadius: "12px",
          overflow: "auto",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          mt: 2,
        }}
      >
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}> Policy </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {" "}
                  Assigned Date{" "}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {" "}
                  Effective Date{" "}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}> Target Date </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {" "}
                  Completed Date{" "}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {" "}
                  Assignment Status{" "}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {" "}
                  Assessment Status{" "}
                </TableCell>
                {/* <TableCell sx={{ fontWeight: "bold" }}> Attempts </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.map((policy) => (
                <TableRow key={policy.policy_id}>
                  <TableCell> {policy.policy_name} </TableCell>
                  <TableCell>{formatDate(policy.assigned_date)}</TableCell>
                  <TableCell>{formatDate(policy.effective_date)}</TableCell>
                  <TableCell>{formatDate(policy.target_date)}</TableCell>
                  <TableCell>{formatDate(policy.completed_date)}</TableCell>
                  <TableCell>
                    <Chip
                      label={policy.assignment_status}
                      color={
                        policy.assignment_status === "Completed"
                          ? "success"
                          : policy.assignment_status === "Assigned"
                          ? "warning"
                          : "default"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={
                        policy.assessment_status === "NotStarted"
                          ? "Not Attempted"
                          : policy.assessment_status
                      }
                      color={
                        policy.assessment_status === "Completed"
                          ? "success"
                          : policy.assessment_status === "NotStarted"
                          ? "error"
                          : "default"
                      }
                    />
                  </TableCell>
                  {/* <TableCell align="center">{policy.attempts || 0}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default Competencies;
