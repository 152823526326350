import React, { useEffect, useState } from "react";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routers } from "../../Configurations/configurationUI";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import { useTheme } from "@mui/material/styles";
import ReorderIcon from '@mui/icons-material/Reorder';

const EditPolicyTypes = () => { 
  useAuthHeaders();
  const [formData, setFormData] = useState({
    type_name: "",
    type_description: "",
    departments_involved: "",
    type_status: "Active",
  });

  const [typeName, setTypeName] = useState("");
  const [typeDescription, setTypeDescription] = useState("");
  const [departmentName, setDepartmentName] = useState("");

  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  console.log(selectedDepartments,"selectedDepartments")

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const typeId = queryParams.get("type_id");
  const theme = useTheme();

  useEffect(() => {
    // Fetch policy type details
    axiosInstance
      .get(ApiUrls.POLICY_TYPE + "/" + typeId)
      .then((response) => {
        const data = response.data;
        setTypeName(data.type_name || "");
        setTypeDescription(data.type_description || "");
        const parsedDepartments = JSON.parse(data.departments_involved || "[]");
        setSelectedDepartments(parsedDepartments);
        setFormData({
          ...formData,
          type_name: data.type_name || "",
          type_description: data.type_description || "",
          departments_involved: parsedDepartments,
        });
      })
      .catch((error) =>
        console.error("Error fetching policy type details:", error)
      );

    // Fetch departments
    axiosInstance
      .get(ApiUrls.DEPARTMENT_API)
      .then((response) => setDepartments(response.data))
      .catch((error) => console.error("Error fetching departments:", error));
  }, [typeId]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.type_name) newErrors.type_name = "Type name is required";
    if (!formData.type_description)
      newErrors.type_description = "Description is required";
    if (!selectedDepartments.length)
      newErrors.departments_involved = "Please choose atlest one Department";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "type_name") setTypeName(value);
    if (name === "type_description") setTypeDescription(value);
  };

  const handleDepartmentSelect = (event) => {
    const { value } = event.target;
    const selected = typeof value === "string" ? value.split(",") : value;
    setSelectedDepartments(selected);
    setFormData((prev) => ({
      ...prev,
      departments_involved: JSON.stringify(selected),
    }));
  };

  function getStyles(name, selectedDepartments, theme) {
    return {
      fontWeight: selectedDepartments.includes(name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        ...formData,
        departments_involved: JSON.stringify(selectedDepartments),
      };

      axiosInstance
        .put(`${ApiUrls.POLICY_TYPE}/${typeId}`, payload)
        .then((response) => {
          setAlert({
            open: true,
            message: "Policy type updated successfully",
            severity: "success",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
            navigate(routers.policytypes);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            open: true,
            message: "Error updating policy type",
            severity: "error",
          });
          console.error("Error during PUT request:", error);
        });
    } else {
      setAlert({
        open: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
    }
  };

  const handleClear = () => {
    setFormData({
      type_name: "",
      type_description: "",
      departments_involved: "",
    });
    setSelectedDepartments([]);
    setErrors({});
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div> 
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert> 
      )}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "10px",
          gap: "18px",
          marginTop: "60px",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
        <ReorderIcon sx={{mr:2}}/>
          Edit Type Here
        </Typography> 
        
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            label="Type Name"
            variant="outlined"
            name="type_name"
            value={typeName}
            onChange={handleChange}
            error={!!errors.type_name}
            helperText={errors.type_name}
            sx={{ width: "48%" }}
          />

          <FormControl sx={{ width: "48%" }} error={!!errors.departments_involved}>
            <InputLabel >Departments</InputLabel>
            <Select
              label="departments"
              id="departments"
              multiple
              value={selectedDepartments}
              onChange={handleDepartmentSelect}
              input={<OutlinedInput label="Departments" />}
              MenuProps={MenuProps}
            >
              {departments.map((department) => (
                <MenuItem
                  key={department.department_id}
                  value={department.department_id}
                  style={getStyles(
                    department.department_name,
                    selectedDepartments,
                    theme
                  )} 
                >
                  {department.department_name}
                </MenuItem>
              ))}
            </Select> 
            {errors.departments_involved && (
              <Typography variant="caption" color="error">
                {errors.departments_involved}
              </Typography>
            )}
          </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
        <TextField
            label="Description"
            variant="outlined"
            name="type_description"
            multiline
            rows={4}
            value={formData.type_description}
            onChange={handleChange}
            error={!!errors.type_description}
            helperText={errors.type_description}
            sx={{ width: "100%" }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            onClick={handleClear}
            sx={{ backgroundColor: "#E5F2FF", color: "black", width: "48%" }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ backgroundColor: "#1976d2", color: "#fff", width: "48%" }}
          >
            Submit
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default EditPolicyTypes;
