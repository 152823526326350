import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Navigation from "../../Components/NavigationBar/Navigation";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";

// Sample report data
const reportData = [
  {
    id: 1,
    policyName: "Data Privacy Policy",
    acknowledgments: 120,
    completionRate: 90,
    competencies: 80,
    complianceStatus: "On Track",
    dueDate: "2024-10-31",
  },
  {
    id: 2,
    policyName: "Code of Conduct",
    acknowledgments: 150,
    completionRate: 75,
    competencies: 85,
    complianceStatus: "Needs Attention",
    dueDate: "2024-11-15",
  },
  {
    id: 3,
    policyName: "Anti-Bribery Policy",
    acknowledgments: 100,
    completionRate: 95,
    competencies: 90,
    complianceStatus: "Compliant",
    dueDate: "2024-12-01",
  },
];

const formatDate = (date) => {
  if (!date) return "-";
  const parsedDate = new Date(date);
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const year = parsedDate.getFullYear();
  return `${day}/${month}/${year}`;
};

const Reports = () => { 
  useAuthHeaders();
  const [policy, setPolicy] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/report/completed/assignment")
      .then((response) => setPolicy(response.data))
      .catch((error) => console.error("Error fetching recents:", error));
  }, []);

  return (
    <div>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Policy Compliance Reports
      </Typography>

      {/* <Typography
                  variant="subtitle1"
                  sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                >
                  {`Total Reports: ${reportData.length}`}
                </Typography> */}
      <Paper
        sx={{
          borderRadius: "25px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          mt: 5,
        }}
      >
        {/* Report Summary Table */}
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Policy</TableCell>
                {/* <TableCell
                  sx={{ fontWeight: "bold" }}
                  align="center"
                >
                  Acknowledgments
                </TableCell> */}
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Completion Rate
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Policy Start Date
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Policy End Date
                </TableCell>
                {/* <TableCell
                  sx={{ fontWeight: "bold" }}
                  align="center"
                >
                  Due Date
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {policy.map((policy) => (
                <TableRow key={policy.id}>
                  <TableCell>{policy.policy_name}</TableCell>
                  {/* <TableCell align="center">{report.acknowledgments}</TableCell> */}
                  <TableCell align="center">
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* Background Circle */}
                      <CircularProgress
                        variant="determinate"
                        value={100}
                        size={45}
                        thickness={5}
                        sx={{
                          color: "#e0e0e0",
                          position: "absolute",
                        }}
                      />

                      {/* Foreground Circle */}
                      <CircularProgress
                        variant="determinate"
                        value={policy.completedPercentage}
                        size={45}
                        thickness={5}
                        sx={{
                          color: "green",
                        }}
                      />

                      {/* Centered Percentage */}
                      <Box
                        sx={{
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          sx={{ fontSize: "10px", color: "#000" }}
                        >
                          {`${policy.completedPercentage}`}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell align="center">
                    {formatDate(policy.effective_date)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(policy.target_date)}
                  </TableCell>
                  {/* <TableCell
                    align="center"
                    sx={{
                      color:
                        report.complianceStatus === "On Track"
                          ? "green"
                          : report.complianceStatus === "Needs Attention"
                          ? "orange"
                          : "blue",
                    }}
                  >
                    {report.complianceStatus}
                  </TableCell> */}
                  {/* <TableCell align="center">{report.dueDate}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default Reports;
