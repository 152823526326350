import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  Tabs,
  Tab,
  Autocomplete,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import BookIcon from "@mui/icons-material/Book";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import Chip from "@mui/material/Chip";

const formatDate = (date) => {
  if (!date) return "-";
  const parsedDate = new Date(date);
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const year = parsedDate.getFullYear();
  return `${day}/${month}/${year}`;
};

const HomePage = () => {
  useAuthHeaders();

  const [activeTab, setActiveTab] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [recentPolicy, setRecentPolicy] = useState([]);
  const [recent, setRecent] = useState([]);

  const [assesment, setAssesment] = useState([]);

  const navigate = useNavigate();
  const userInfo = JSON.parse(sessionStorage.getItem("loggedinUser"));
  const userId = sessionStorage.getItem("loggedinUserId");
  
  useEffect(() => {
    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/By/userId")
      .then((response) => setRecentPolicy(response.data))
      .catch((error) =>
        console.error("Error fetching recentspolicies:", error)
      );

    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/threedaysbefore/notcompletedstatus")
      .then((response) => setRecent(response.data))
      .catch((error) => console.error("Error fetching recents:", error));

    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/notcompleted/assessment")
      .then((response) => setAssesment(response.data))
      .catch((error) => console.error("Error fetching Assesments:", error));
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleFilterDrawer = () => {
    setFilterOpen(true);
  };

  const handleSearch = () => {
    if (keyword.trim()) {
      navigate(`/searchresults?q=${encodeURIComponent(keyword.trim())}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Box sx={{ backgroundColor: "#f7fbfd" }}>
      <Box
        sx={{
          // backgroundImage: `url('https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
          backgroundColor: "#E5F2FF",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "8px",
          padding: "40px 20px",
          textAlign: "center",
          color: "white",
          maxWidth: "1200px",
          margin: "20px auto",
          marginTop: "9px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#1D3557",
          }}
        >
          What would you like to learn today?
        </Typography>
        <Autocomplete
          freeSolo
          options={[]}
          value={keyword}
          onInputChange={(event, newValue) => setKeyword(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search..."
              onKeyDown={handleKeyDown}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                    <IconButton onClick={toggleFilterDrawer}>
                      <FilterListIcon />
                    </IconButton>
                  </>
                ),
                style: {
                  height: "60px",
                  width: "80%",
                  backgroundColor: "#fff",
                  borderRadius: "30px",
                  marginLeft: "10%",
                },
              }}
            />
          )}
        />
      </Box>

      <Box
        sx={{
          textAlign: "center",
          marginTop: "20px",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "10px" }}
        >
          Greetings, {userInfo.first_name}! Explore, Updated Policies.
        </Typography>
      </Box>

      {/* Tab Section */}
      <Card
        sx={{
          // maxWidth: "1200px",

          margin: "20px auto",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{ marginBottom: "20px" }}
        >
          <Tab label="Recent Policies" />
          <Tab label="Recent Learning" />
          <Tab label="My Assessments" />
        </Tabs>
        <Box>
          {activeTab === 0 && (
            <List>
              {recentPolicy.slice(-3).map((policy, index) => (
                <ListItem
                  key={index}
                  sx={{
                    backgroundColor: "#f7f7f9",
                    height: "60px",
                    margin: "5px 0",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "#e0f7fa",
                      cursor: "pointer",
                    },
                  }} 
                  onClick={() =>
                    navigate(`/policydetails?policy_id=${policy.policy_id}&assignment_id=${policy.assignment_id}`) 
                   
                  } 
                >
                  <ListItemIcon>
                    <BookIcon sx={{ color: "#2196f3" }} />
                  </ListItemIcon>
                  <ListItemText primary={policy.policy_name} />
                  <Chip
                    label={formatDate(policy.effective_date)}
                    sx={{
                      backgroundColor: policy.effective_date
                        ? "#d4edda"
                        : "#f8d7da",
                      color: policy.effective_date ? "#155724" : "#721c24",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          )}
          {activeTab === 1 && (
            <List>
              {recent.map((policy, index) => (
                <ListItem
                  key={index}
                  sx={{
                    backgroundColor: "#f7f7f9",
                    height: "60px",
                    margin: "5px 0",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "#e0f7fa",
                      cursor: "pointer",
                    },
                  }} 
                  onClick={() =>
                    navigate(`/policydetails?policy_id=${policy.policy_id}&assignment_id=${policy.assignment_id}`) 
              
                  }
                >
                  <ListItemIcon>
                    <BookIcon sx={{ color: "#2196f3" }} />
                  </ListItemIcon>
                  <ListItemText primary={policy.policy_name} />
                </ListItem>
              ))}
            </List>
          )}
          {activeTab === 2 && (
            <List>
              {assesment.map((exam, index) => (
                <ListItem
                  key={index}
                  sx={{
                    backgroundColor: "#f7f7f9",
                    height: "60px",
                    margin: "5px 0",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "#e0f7fa",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() =>
                    navigate(`/policydetails?policy_id=${exam.policy_id}&assignment_id=${exam.assignment_id}`) 
                    
                  }
                >
                  <ListItemIcon>
                    <AssignmentIcon sx={{ color: "#155724" }} />
                  </ListItemIcon>
                  <ListItemText primary={exam.policy_name} />
                  <Chip
                    label={
                      exam.assessment_status === "NotStarted"
                        ? "Not Started"
                        : exam.assessment_status
                    }
                    sx={{
                      backgroundColor:
                        exam.assessment_status === "NotStarted"
                          ? "#d4edda"
                          : "#ffcccc",
                      color:
                        exam.assessment_status === "NotStarted"
                          ? "#155724"
                          : "#cc0000",
                      marginRight: "220px",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default HomePage;
