import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {Card,Typography,CardActions, Button, Box, IconButton, CircularProgress,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const FavouritesList = () => { 
  useAuthHeaders();
  const [favourites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   axiosInstance
  //     .get(ApiUrls.FAVOURITE_API + "/By/UserId")
  //     .then((response) => {
  //       setFavorites(response.data);
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       setError("An error occurred while fetching the Favourites.");
  //       setLoading(false);
  //     });
  // }, []);
  useEffect(() => {
    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API + "/By/UserId")
      .then((response) => {
        const filteredFavourites = response.data.filter(item => item.is_favourite === 1);
        setFavorites(filteredFavourites);
        setLoading(false);
      })
      .catch(() => {
        setError("An error occurred while fetching the Favourites.");
        setLoading(false);
      });
  }, []);

  const toggleStar = (assignmentId) => {
    axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId, {
      is_favourite: false,
    }
    )
      .then(() => {
        setFavorites((prevFavorites) =>
          prevFavorites.filter((item) => item.assignment_id !== assignmentId)
        );
      })
      .catch(() => {
        setError("Failed to remove the favourite. Please try again.");
      });
  };

  const renderFileIcon = (fileType) => {
    // if (fileType === "pdf") {
    //   return <PictureAsPdfIcon sx={{ fontSize: 40, color: "#d32f2f" }} />;
    // } else if (fileType === "doc") {
    //   return <InsertDriveFileIcon sx={{ fontSize: 40, color: "#1976d2" }} />;
    // } else {
    //   return <InsertDriveFileIcon sx={{ fontSize: 40, color: "#9e9e9e" }} />;
    // }
    return <PictureAsPdfIcon sx={{ fontSize: 40, color: "#d32f2f" }} />;
  };

  const handleCardClick = (policy) => {
    const policyId= policy.policy_id;
    const assignmentId = policy.assignment_id
    axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId,
       {
        last_visited: new Date()
        }
    )
    .then(()=>{
      navigate(`/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`);
    })
    .catch(()=>{
      navigate(`/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`);
    })
   
  };

  return (
    <div>
      <Typography variant="h6" sx={{ fontWeight: "bold", flex: 1, mb: 4 }}>
        My Favorite Reads
      </Typography>

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {error && !loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            color: "error.main", // Red color for error messages from Material-UI theme
            textAlign: "center",
          }}
        >
          <Typography variant="h6">{error}</Typography>
        </Box>
      )}

      {!loading && !error && favourites.length === 0 && (
        <Box
          sx={{
            display: "flex",
            
            minHeight: "200px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">No favourites found for this type.</Typography>
        </Box>
      )}

      {!loading && !error && favourites.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            padding: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {favourites.map((policy) => (
            <Card
              key={policy.assignment_id}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                boxShadow: "none",
              }}
              onClick={() => handleCardClick(policy)}
            >
              <Box
                sx={{
                  flexShrink: 0,
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "16px",
                }}
              >
                {renderFileIcon(policy.file_type)}
              </Box>

              {/* Content Section */}
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#232f3e",
                    marginBottom: "8px",
                  }}
                >
                  {policy.policy_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginBottom: "8px" }}
                >
                  {policy.policy_description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Expiration Date:</strong>{" "}
                  {new Date(policy.expiration_date).toLocaleDateString()}
                </Typography>
              </Box>

              {/* Star Icon Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  marginLeft: "16px",
                }}
              >
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleStar(policy.assignment_id);
                  }}
                >
                  <StarIcon sx={{ color: "#fdd835" }} />
                </IconButton>

                <CardActions sx={{ padding: 0 }}>
                  <Button size="small" variant="text" color="primary">
                    View Details
                  </Button>
                </CardActions>
              </Box>
            </Card>
          ))}
        </Box>
      )}
    </div>
  );
};

export default FavouritesList;
