import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import { routers } from "../../Configurations/configurationUI"; 

const EditUser = () => { 
  useAuthHeaders();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    primary_email: "",
    primary_phone: "",
    dob: "",
    location_id: "",
    department_id: "",
    role_id: "",
  });

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [dob, setDob] = useState("");
  const [getLocation, setGetLocation] = useState("");
  const [getDepartment, setGetDepartment] = useState("");
  const [role, setRole] = useState("");

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectRole, setSelectRole] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user_id");

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.USER_API + "/" + userId)
      .then((response) => {
        const data = response.data;
        const formattedDob = data.dob
          ? new Date(data.dob).toISOString().split("T")[0]
          : "";
        setFirstName(data.first_name || "");
        setLastName(data.last_name || "");
        setPrimaryEmail(data.primary_email || "");
        setPrimaryPhone(data.primary_phone || "");
        setDob(formattedDob);
        setSelectedLocation(data.location_id || "");
        setSelectDepartment(data.department_id || "");
        setRole(data.role_id || "");

        setLoadingData(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoadingData(false);
      });

    axiosInstance
      .get(ApiUrls.LOCATION_API)
      .then((response) => setLocations(response.data))
      .catch((error) => console.error("Error fetching locations:", error));

    axiosInstance
      .get(ApiUrls.DEPARTMENT_API)
      .then((response) => setDepartments(response.data))
      .catch((error) => console.error("Error fetching departments:", error)); 


    axiosInstance
      .get(ApiUrls.ROLE_API)
      .then((response) => setRoles(response.data))
      .catch((error) => console.error("Error fetching roles:", error));
  }, []);

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.DEPARTMENT_API + "/location/" + selectedLocation)
      .then((response) => setDepartments(response.data))
      .catch((error) => console.error("Error fetching departments:", error));
  }, [selectedLocation]);

  const validateForm = () => {
    const newErrors = {}; 

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    
    if (!firstname) newErrors.firstname = "First name is required";
    if (!lastname) newErrors.lastname = "Last name is required";
    if (!primaryEmail) {newErrors.primaryEmail = "Email is required"} 
    else if (!emailRegex.test(primaryEmail)) {
      newErrors.primaryEmail = "Invalid email format (e.g., abc@gmail.com)";
    };
    if (!primaryPhone) newErrors.primaryPhone = "Phone number is required";
    if (!dob) newErrors.dob = "Date of birth is required";
    if (!selectDepartment) newErrors.selectDepartment = "Location is required";
    if (!selectDepartment) newErrors.selectDepartment = "Department is required";
    if (!role) newErrors.role = "Role is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "location") {
      setFormData({ ...formData, location_id: value });
      setSelectedLocation(value);
    }

    if (name === "department") {
      setFormData({ ...formData, department_id: value });
      setSelectDepartment(value);
    }

    if (name === "role") {
      setFormData({ ...formData, role_id: value });
      setSelectRole(value);
    }
  };

  const handleSubmit = () => {
    const updatedFormData = {
      first_name: firstname,
      last_name: lastname,
      primary_email: primaryEmail,
      primary_phone: primaryPhone,
      dob: dob,
      location_id: selectDepartment,
      department_id: selectDepartment,
      role_id: role,
    };
    if (validateForm()) {
      axiosInstance
        .put(ApiUrls.USER_API + "/" + userId, updatedFormData)
        .then((response) => {
          setAlert({
            show: true,
            message: "User Updated successfully",
            severity: "success",
          });
          setTimeout(() => {
            console.log("User Updated successfully:", response.data);
            setAlert((alert) => ({ ...alert, show: false }));
            navigate(routers.userlist);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error Edit user",
            severity: "error",
          });
          console.error("Error Editing User:", error);
        });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
    }
  };

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <Typography variant="h5" sx={{ mb: 10 }}>
        Edit User
      </Typography>

      <Paper
        sx={{
          padding: "20px",
          mt: -5,
          backgroundColor: "#e3f2fd",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            height: "90%",
            maxHeight: "100%",
            overflowY: "auto",
            paddingBottom: "20px",
          }}
          className="custom-scrollbar"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "6px",
            }}
          >
            <TextField
              label="First Name"
              variant="outlined"
              name="first_name"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
              error={!!errors.firstname}
              helperText={errors.firstname}
              style={{ width: "45%" }}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              name="last_name"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
              error={!!errors.lastname}
              helperText={errors.lastname}
              style={{ width: "45%" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "3px",
            }}
          >
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              name="primary_email"
              value={primaryEmail}
              onChange={(e) => setPrimaryEmail(e.target.value)}
              error={!!errors.primaryEmail}
              helperText={errors.primaryEmail}
              style={{ width: "95%" }}
            />
            {/* <TextField
                    label="Create Password"
                    variant="outlined"
                    type="password"
                    name="password"
                    autoComplete="false"
                    autoCapitalize="false"
                    value={formData.password}
                    onChange={handleChange}
                    error={!!errors.password}
                    helperText={errors.password}
                    style={{ width: "45%" }}
                  /> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "2px",
            }}
          >
            <TextField
              label="Phone"
              variant="outlined"
              type="tel"
              name="primary_phone"
              value={primaryPhone}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setPrimaryPhone(value);
                }
              }}
              error={!!errors.primaryPhone}
              helperText={errors.primaryPhone}
              style={{ width: "45%" }}
            /> 


            <TextField
              label="Date of Birth"
              variant="outlined"
              type="date"
              name="dob"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              error={!!errors.dob}
              helperText={errors.dob}
              InputLabelProps={{ shrink: true }}
              style={{ width: "45%" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around", 
              
              width:"100%"
            }}
          >
            <div style={{ width: "45%"  }}>
              <FormControl variant="outlined" error={!!errors.role_id} style={{ width: "100%" }}>
                <InputLabel>Role</InputLabel>
                <Select
                  label="role"
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  
                >
                  {roles.length > 0 ? (
                    roles.map((Role) => (
                      <MenuItem key={Role.role_id} value={Role.role_id}>
                        {Role.role_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No Roles available</MenuItem>
                  )}
                </Select>
                {errors.role_id && (
                  <Typography
                    color="error"
                    style={{ marginLeft: "20PX" }}
                    variant="caption"
                  >
                    {errors.role_id}
                  </Typography>
                )}
              </FormControl>
            </div>

            <div style={{ width: "45%", }}>
              <FormControl
                variant="outlined"
                error={!!errors.location_id}
                style={{ width: "100%" }}
              >
                <InputLabel>Location</InputLabel>
                <Select
                  label="Location"
                  name="location"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                 
                >
                  {locations.length > 0 ? (
                    locations.map((loc) => (
                      <MenuItem key={loc.location_id} value={loc.location_id}>
                        {loc.location_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No locations available</MenuItem>
                  )}
                </Select>
                {errors.location_id && (
                  <Typography
                    color="error"
                    style={{ marginLeft: "20PX" }}
                    variant="caption"
                  >
                    {errors.location_id}
                  </Typography>
                )}
              </FormControl>
            </div>
          </div>

          <div
            style={{
              
              marginTop: "2px",
              marginLeft: "50px",
              width: "95%",
            }}
          >
            <FormControl
              variant="outlined"
              error={!!errors.department_id}
              style={{ width: "100%", marginLeft:"-20px" }}
            >
              <InputLabel>Department</InputLabel>
              <Select
                label="Department"
                name="department"
                value={selectDepartment}
                onChange={(e) => setSelectDepartment(e.target.value)}
                // style={{ width: "32vw" }}
              >
                {departments.length > 0 ? (
                  departments.map((dep) => (
                    <MenuItem key={dep.department_id} value={dep.department_id}>
                      {dep.department_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Department available</MenuItem>
                )}
              </Select>
              {errors.department_id && (
                <Typography
                  color="error"
                  style={{ marginLeft: "20PX" }}
                  variant="caption"
                >
                  {errors.department_id}
                </Typography>
              )}
            </FormControl>
          </div>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#1976d2",
              color: "#fff",
              width: "95%",
              ml: 4,
            }}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

export default EditUser;
