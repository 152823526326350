import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { axiosInstance, useAuthHeaders } from "../../Utils/axiosInstance";
import Temp from "../../Templats/Template";
import UserImport from "./UserImport";
import { routers } from "../../Configurations/configurationUI";

const AddUser = () => {
  useAuthHeaders();
  const userId = parseInt(sessionStorage.getItem("loggedinUserId"));
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    primary_email: "",
    password: "",
    primary_phone: "",
    dob: "",
    location_id: "",
    department_id: "",
    role_id: "",
    created_at: new Date(),
    last_updated_at: "",
    created_by: userId,
    last_updated_by: 0,
  });

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectRole, setSelectRole] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.LOCATION_API)
      .then((response) => setLocations(response.data))
      .catch((error) => console.error("Error fetching locations:", error));

    axiosInstance
      .get(ApiUrls.ROLE_API)
      .then((response) => setRoles(response.data))
      .catch((error) => console.error("Error fetching roles:", error));
  }, []);

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.DEPARTMENT_API + "/location/" + selectedLocation)
      .then((response) => setDepartments(response.data))
      .catch((error) => console.error("Error fetching departments:", error));
  }, [selectedLocation]);

  const validateForm = () => {
    const newErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.first_name) newErrors.first_name = "First name is required";
    if (!formData.last_name) newErrors.last_name = "Last name is required";
    if (!formData.primary_email) {
      newErrors.primary_email = "Email is required";
    } else if (!emailRegex.test(formData.primary_email)) {
      newErrors.primary_email = "Invalid email format (e.g., abc@gmail.com)";
    }
    if (!formData.password) newErrors.password = "Password is required";
    if (!formData.primary_phone)
      newErrors.primary_phone = "Phone number is required";
    if (!formData.dob) newErrors.dob = "Date of birth is required";
    if (!formData.location_id) newErrors.location_id = "Location is required";
    if (!formData.department_id)
      newErrors.department_id = "Department is required";
    if (!formData.role_id) newErrors.role_id = "Role is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "location") {
      setFormData({ ...formData, location_id: value });
      setSelectedLocation(value);
    }

    if (name === "department") {
      setFormData({ ...formData, department_id: value });
      setSelectDepartment(value);
    }

    if (name === "role") {
      setFormData({ ...formData, role_id: value });
      setSelectRole(value);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      axiosInstance
        .post(ApiUrls.CREATE_USER, formData)
        .then((response) => {
          setAlert({
            show: true,
            message: "User added successfully",
            severity: "success",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
            navigate(routers.userlist);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error adding user",
            severity: "error",
          });
          console.error("Error adding user:", error);
        });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
    }
  };

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <div
        style={{
          backgroundColor: "#E5F2FF",
          borderRadius: "15px",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>
          <h2
            style={{ fontWeight: "bold", color: "#1D3557", fontSize: "31px" }}
          >
            Adding User is Essential.
          </h2>
          <p
            style={{
              backgroundColor: "white",
              borderRadius: "50px",
              padding: "1rem 2rem",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              marginTop: "1rem",
              fontSize: "16px",
              lineHeight: "1.5",
            }}
          >
            Add User to streamline the process of managing access This will help
            in organizing resources and managing for user.
          </p>
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <img
            src="https://images.pexels.com/photos/1181279/pexels-photo-1181279.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Location Image"
            style={{
              width: "50%",
              height: "auto",
              borderRadius: "15px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              objectFit: "cover",
              clipPath: "polygon(19% 0%, 100% 0%, 100% 100%, 0% 100%)",
            }}
          />
        </div>
      </div>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "10px",
          gap: "18px",
          marginTop: "60px",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
          <i
            className="fa fa-users"
            style={{ fontSize: "22px", color: "black", paddingRight: "15px" }}
          />
          Add User Here
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            label="First Name *"
            variant="outlined"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            error={!!errors.first_name}
            helperText={errors.first_name}
            style={{ width: "45%" }}
          />
          <TextField
            label="Last Name *"
            variant="outlined"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            error={!!errors.last_name}
            helperText={errors.last_name}
            style={{ width: "45%" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            label="Email *"
            variant="outlined"
            type="email"
            name="primary_email"
            value={formData.primary_email}
            onChange={handleChange}
            error={!!errors.primary_email}
            helperText={errors.primary_email}
            style={{ width: "45%" }}
          />
          <TextField
            label="Create Password *"
            variant="outlined"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
            style={{ width: "45%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            label="Phone *"
            variant="outlined"
            type="tel"
            name="primary_phone"
            value={formData.primary_phone}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleChange(e);
              }
            }}
            error={!!errors.primary_phone}
            helperText={errors.primary_phone}
            style={{ width: "45%" }}
          />
          <TextField
            label="Date of Birth"
            variant="outlined"
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            error={!!errors.dob}
            helperText={errors.dob}
            InputLabelProps={{ shrink: true }}
            style={{ width: "45%" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "45%" }}>
            <FormControl
              variant="outlined"
              error={!!errors.role_id}
              style={{ width: "100%" }}
            >
              <InputLabel>Role</InputLabel>
              <Select
                label="role"
                name="role"
                value={selectRole}
                onChange={handleChange}
              >
                {roles.length > 0 ? (
                  roles.map((Role) => (
                    <MenuItem key={Role.role_id} value={Role.role_id}>
                      {Role.role_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Roles available</MenuItem>
                )}
              </Select>
              {errors.role_id && (
                <Typography
                  color="error"
                  variant="caption"
                  style={{ marginLeft: "20px" }}
                >
                  {errors.role_id}
                </Typography>
              )}
            </FormControl>
          </div>
          <div style={{ width: "45%" }}>
            <FormControl
              variant="outlined"
              error={!!errors.location_id}
              style={{ width: "100%" }}
            >
              <InputLabel>Location</InputLabel>
              <Select
                label="Location"
                name="location"
                value={selectedLocation}
                onChange={handleChange}
              >
                {locations.length > 0 ? (
                  locations.map((loc) => (
                    <MenuItem key={loc.location_id} value={loc.location_id}>
                      {loc.location_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No locations available</MenuItem>
                )}
              </Select>
              {errors.location_id && (
                <Typography
                  color="error"
                  style={{ marginLeft: "20PX" }}
                  variant="caption"
                >
                  {errors.location_id}
                </Typography>
              )}
            </FormControl>
          </div>
        </div>

        <div
          style={{
            marginTop: "2px",
            marginLeft: "20px",
            width: "100%",
          }}
        >
          {selectedLocation && (
            <FormControl
              variant="outlined"
              error={!!errors.department_id}
              style={{ width: "100%", marginLeft: "-20px" }}
            >
              <InputLabel>Department</InputLabel>
              <Select
                label="Department"
                name="department"
                value={selectDepartment}
                onChange={handleChange}
              >
                {departments.length > 0 ? (
                  departments.map((dep) => (
                    <MenuItem key={dep.department_id} value={dep.department_id}>
                      {dep.department_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Department available</MenuItem>
                )}
              </Select>
              {errors.department_id && (
                <Typography
                  color="error"
                  variant="caption"
                  style={{ marginLeft: "20px" }}
                >
                  {errors.department_id}
                </Typography>
              )}
            </FormControl>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            // onClick={handleClear}
            sx={{ backgroundColor: "#E5F2FF", color: "black", width: "48%" }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#1976d2",
              color: "#fff",
              width: "48%",
              ml: 3,
            }}
          >
            Submit
          </Button>
        </div>
      </Box>
      <div
        style={{
          marginTop: "60px",
          marginBottom: "10px",
          paddingBottom: "30px",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          Import Users In Bulk
        </Typography>
        <UserImport />
      </div>
    </div>
  );
};

export default AddUser;
