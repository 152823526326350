import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
  Alert,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls"; 


const EditConfiguration = () => {  
  useAuthHeaders();
  const [config, setConfig] = useState({
    organization_type: "",
    organization_name: "",
    organization_description: "",
  });
  const [logo, setLogo] = useState(null);
  const [organisationId, setOrganisationId] = useState(null);
  const [originalConfig, setOriginalConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(ApiUrls.Organization_API)
      .then((response) => {
        if (response && response.data.length > 0) {
          const details = response.data[0];
          setConfig({
            organization_type: details.organization_type,
            organization_name: details.organization_name,
            organization_description: details.organization_description,
          });
          setOrganisationId(details.organization_id);
          setOriginalConfig(details);
        } else {
          setConfig({
            organization_type: "",
            organization_name: "",
            organization_description: "",
          });
          setOrganisationId(null);
          setOriginalConfig(null);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setAlert({
          message: "Failed to load organization details.",
          severity: "error",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      });
  }, []);

  const handleInputChange = (field) => (event) => {
    setConfig({ ...config, [field]: event.target.value });
    setHasChanges(true);
  };  

  const [errors, setErrors] = useState({
    organization_name: "",
    organization_type: "",
    organization_description: "",
  }); 

  const validateFields = () => {
    const newErrors = {};
    if (!config.organization_name.trim()) {
      newErrors.organization_name = "Organization name is required.";
    }
    if (!config.organization_type.trim()) {
      newErrors.organization_type = "Organization type is required.";
    }
    if (!config.organization_description.trim()) {
      newErrors.organization_description = "Organization description is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
  };

  const handleSubmitOrg = () => { 
    if (!validateFields()) return;
    axiosInstance
      .post(ApiUrls.Organization_API, config)
      .then((response) => {
        setAlert({
          message: "Organization Added Successfully",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      })
      .catch((error) => {
        setAlert({
          message: "Error Adding Organization",
          severity: "error",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      });
  };

  const handleSaveOrg = () => { 
    if (!validateFields()) return;
    axiosInstance
      .put(ApiUrls.Organization_API + "/" + organisationId, config)
      .then((response) => {
        setOriginalConfig(response.data);
        setOrganisationId(response.data.organization_id);
        setAlert({
          message: "Organization edited Successfully",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
        setHasChanges(false);
      })
      .catch(() => {
        setAlert({
          message: "Error while editing Organization, Please try again later",
          severity: "error",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
        setHasChanges(true);
      });
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
    }
  };

  const handleRemoveLogo = () => {
    setLogo(null);
  };
  const handleUploadLogo = async () => {
    if (!logo) {
      setAlert({
        message: "Please add logo before sumbit",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
      return;
    }

    try {
      const fileData = new FormData();
      const fileStorageInfo = {
        organization_id: 1,
        file_folder: "ORGANIZATION_PHOTO",
      };
      fileData.append("FileStorageInfo", JSON.stringify(fileStorageInfo));
      const fileExtension = logo.name.split(".").pop();
      const customFileName = `${1}.${fileExtension}`;
      const mimeType = logo.type || "application/octet-stream";

      const newFile = new File([logo], customFileName, {
        type: mimeType,
        lastModified: logo.lastModified,
      });

      fileData.append("files", newFile);
      const response = await axiosInstance.post(
        ApiUrls.FILESTORAGE_API + "/upload",
        fileData
      );
      if (response) {
        setLogo(null);
        setAlert({
          message: "Logo Uploaded Successfully",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      }
    } catch (error) {
      setLogo(null);
      setAlert({
        message: "Failed to upload Logo, Try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    }
  };

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "60%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <Typography
        variant="h4"
        sx={{ mb: 3, fontWeight: "bold", color: "#333" }}
      >
        Edit Configuration
      </Typography>
      <Paper
        sx={{
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          borderRadius: "10px",
          border: "1px solid black",
        }}
      >
        {/* Organization Name Section */}
        <Paper
          sx={{
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#555" }}>
            Add Organization Details
          </Typography>
          <TextField
            label="Organization Name"
            value={config.organization_name}
            onChange={handleInputChange("organization_name")}
            variant="outlined"
            fullWidth
            required
            sx={{ mb: 3, mt: 2 }}
            error={!!errors.organization_name}
            helperText={errors.organization_name}
          />
          <TextField
            label="Organization Type"
            value={config.organization_type}
            onChange={handleInputChange("organization_type")}
            variant="outlined"
            fullWidth
            required
            sx={{ mb: 3, mt: 2 }}
            error={!!errors.organization_type}
            helperText={errors.organization_type}
          />
          <TextField
            label="Organization Description"
            value={config.organization_description}
            onChange={handleInputChange("organization_description")}
            variant="outlined"
            fullWidth
            required
            sx={{ mb: 3, mt: 2 }}
            error={!!errors.organization_description}
            helperText={errors.organization_description}
          />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            {originalConfig ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveOrg}
                disabled={!hasChanges}
              >
                Save Changes
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitOrg}
              >
                Submit
              </Button>
            )}
          </Box>
        </Paper>

        {/* Organization Logo Section */}
        <Paper
          sx={{
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#555" }}>
            Add Organization Logo
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 3,
            }}
          >
            <input
              accept="image/*"
              id="upload-logo"
              type="file"
              onChange={handleLogoChange}
              style={{ display: "none" }}
              disabled={!originalConfig?.organization_id}
            />
            <label htmlFor="upload-logo">
              <IconButton
                color="primary"
                component="span"
                sx={{
                  marginBottom: "16px",
                  border: "2px dashed #3f51b5",
                  borderRadius: "8px",
                  padding: "16px",
                  "&:hover": {
                    backgroundColor: "#e3f2fd",
                  },
                }}
                disabled={!originalConfig?.organization_id}
              >
                <UploadFileIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </label>
            {logo && (
              <Box sx={{ position: "relative", mb: 3 }}>
                <img
                  src={URL.createObjectURL(logo)}
                  alt="Logo Preview"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "8px",
                    border: "2px solid #3f51b5",
                  }}
                />
                <IconButton
                  onClick={handleRemoveLogo}
                  sx={{
                    position: "absolute",
                    top: -8,
                    right: -8,
                    backgroundColor: "white",
                    color: "red",
                    "&:hover": {
                      backgroundColor: "#f8d7da",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            {originalConfig && originalConfig.organization_id ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadLogo}
                disabled={!originalConfig?.organization_id}
              >
                Upload
              </Button>
            ) : (
              <Typography sx={{ fontWeight: "5rem" }}>
                Add Organisation to upload Logo
              </Typography>
            )}
          </Box>
        </Paper>
      </Paper>
    </div>
  );
};

export default EditConfiguration;
