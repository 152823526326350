import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Paper, TextField, Button, MenuItem, Select, InputLabel, FormControl, Alert, CircularProgress,} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload"; 
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import mammoth from "mammoth";
import ConvertApi from "convertapi-js";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import PolicyEditor from "../../Components/TinyMceEditor/TinyMceEditor";
import CreateQuestion from "../../Components/CustomQandA/CustomQandA";
import { useLocation } from "react-router-dom";

const EditPolicies = () => { 
  useAuthHeaders();
  const [policyName, setPolicyName] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const [policyStartDate, setPolicyStartDate] = useState("");
  const [policyExpireDate, setPolicyExpireDate] = useState("");
  const [departments, setDepartments] = useState([]);
  const [file, setFile] = useState(null);
  const [showEditor, setShowEditor] = useState(false); 
  const [assesmentEditor, setAssesmentEditor] = useState(false);
  const [documentContent, setDocumentContent] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const policyId = searchParams.get("policy_id");
  const editorRef = useRef(null);
  const [policy, setPolicy] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (policyId) {
      axiosInstance
        .get(ApiUrls.POLICY_API + "/" + policyId)
        .then((response) => {
          const data = response.data;
          setPolicyName(data.policy_name || "");
          setPolicyDescription(data.policy_description || "");
          setPolicyStartDate(formatDate(data.effective_date));
          setPolicyExpireDate(formatDate(data.expiration_date));
          setSelectedDepartment(data.department_id || "");
          setSelectedType(data.type_id || "");
          setLoading(false);
        })
        .catch((error) => {
          setError("An error occurred while fetching the policies.");
          setLoading(false);
        });
      axiosInstance
        .get(ApiUrls.DEPARTMENT_API)
        .then((response) => {
          setDepartments(response.data);
        })
        .catch((error) => {});

        axiosInstance.get(ApiUrls.FILESTORAGE_API +"/policydocument/paths/" + policyId)
        .then((response) => {
          const lastFileUrl = response.data[response.data.length - 1]; 
          setFileUrl(lastFileUrl);
        })
        .catch((error) => {
          setError("An error occurred while fetching the policies.");
        });
    }
    axiosInstance
      .get(ApiUrls.POLICY_TYPE)
      .then((response) => {
        setTypes(response.data);
      })
      .catch((error) => {});
  }, [policyId]);

   useEffect(() => {
    axiosInstance.get(ApiUrls.POLICY_TYPE + "/By/departmentId/" + selectedDepartment)
    .then((response) => {
      setTypes(response.data);
    })
    .catch((error) => {});
  }, [selectedDepartment]);

  useEffect(() => {
    const processPdfToHtml = async () => {
      if (!fileUrl) return;
  
      try {
        // Step 1: Fetch the PDF file
        const response = await fetch(fileUrl);
        const pdfBlob = await response.blob();
        // Step 2: Construct FormData for the API request
        const formData = new FormData();
        formData.append("File", pdfBlob, "document.pdf");
        // Step 3: Send POST request to ConvertApi
        const convertApiResponse = await fetch("https://v2.convertapi.com/convert/pdf/to/docx?storefile=true",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer secret_Zgz9z2Etb9yrHJx2`,
            },
            body: formData,
          }
        );
        const result = await convertApiResponse.json();
        const wordFileUrl = result.Files?.[0]?.Url;
        // Step 4: Fetch and process the Word file
        const wordResponse = await fetch(wordFileUrl);
        const wordBlob = await wordResponse.blob();
        const wordFile = new File([wordBlob], "converted.docx", {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        // Step 5: Convert Word document to HTML
        const arrayBuffer = await wordFile.arrayBuffer();
        const resultHtml = await mammoth.convertToHtml({ arrayBuffer });
        setDocumentContent(resultHtml.value);
        setShowEditor(true);
      } catch (error) {
        setAlert({
          message: "Failed to process the PDF file. Please try another file.",
          severity: "error",
          show: true,
        });
        setTimeout(() => setAlert((prev) => ({ ...prev, show: false })), 2000);
      }
    };
  
    processPdfToHtml();
  }, [fileUrl]);
  

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!policyName) newErrors.policyName = "Policy name is required";
    if (!policyDescription)
      newErrors.policyDescription = "Policy description is required";
    if (!policyStartDate) newErrors.policyStartDate = "Start date is required";
    if (!policyExpireDate)
      newErrors.policyExpireDate = "Expiration date is required";
    if (!selectedDepartment)
      newErrors.department = "Please select a department";
    if (!file) newErrors.file = "Please choose a file before submit";
    return newErrors;
  };

  const resetForm = () => {
    setPolicyName("");
    setPolicyDescription("");
    setPolicyStartDate("");
    setPolicyExpireDate("");
    setSelectedDepartment("");
    setFile(null);
    setShowEditor(false);
    setDocumentContent("");

  };

  const handleSubmit = async () => {
      // const validationErrors = validateForm();
      // if (Object.keys(validationErrors).length > 0) {
      //   setErrors(validationErrors);
      //   return;
      // }

    setLoading(true);
    setErrors({});
    setAlert(null);

    const policyPayload = {
      policy_name: policyName,
      policy_description: policyDescription, 
      effective_date: policyStartDate,
      expiration_date: policyExpireDate,
      department_id: selectedDepartment,
      type_id: selectedType,
      updated_by: parseInt(sessionStorage.getItem('loggedinUserId')), 
      assessment_info: "", 
      policy_status: "Assigned to Director",
    };

    try {
      const { data: resData } = await axiosInstance.put(
        ApiUrls.POLICY_API +"/" + policyId,
        policyPayload
      );
      if (!resData.policy_id) {
        throw new Error("Policy Updated failed. No policy ID returned.");
      }

      if (documentContent) {
        const htmlContent = documentContent;
        const pdfUrl = await convertHtmlToPdf(htmlContent); 
        if (pdfUrl) {
          const pdfFile = await fetch(pdfUrl).then((res) => res.blob());
          const pdfFormData = new FormData();
          const fileStorageInfo = {
            type_id:selectedType,
            department_id:selectedDepartment,
            policy_id:resData.policy_id,
            file_folder: "POLICY_DOCUMENT",
          }
          pdfFormData.append("FileStorageInfo", JSON.stringify(fileStorageInfo));
          const customFileName = `${resData.policy_id}.pdf`
          pdfFormData.append("files", pdfFile, customFileName);
          try {
            const fileStorageResponse = await axiosInstance.post(
              `${ApiUrls.FILESTORAGE_API}/upload`,
              pdfFormData
            );
           
          } catch (uploadError) {
            throw new Error("File upload failed");
          }
        }
      }
      resetForm();
      setShowEditor(false);
      setLoading(false);
      setAlert({
        message: "Policy edited  successfully.",
        severity: "success",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        message: "Error while editing Policy. Please try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    }
  };

  const handleContentChange = (newContent) => {
    setDocumentContent(newContent); 
  };

  const convertHtmlToPdf = async (htmlContent) => {
    const convertApi = ConvertApi.auth("secret_Zgz9z2Etb9yrHJx2");
    const params = convertApi.createParams();
    const htmlBlob = new Blob([htmlContent], { type: "text/html" });
    const file = new File([htmlBlob], "document.html", { type: "text/html" });

    try {
      params.add("File", file);
      const result = await convertApi.convert("html", "pdf", params);
      const pdfUrl = result.files[0].Url;
      return pdfUrl;
      // window.open(pdfUrl, "_blank");
    } catch (error) {
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    if (!isNaN(date)) {
      return date.toISOString().split("T")[0];
    }
    return "";
  };

  return (
    <div>
      <Typography
        variant="h4"
        sx={{ mb: 3, fontWeight: "bold", color: "#333" }}
      >
        Edit Policy
      </Typography>
      <Paper
        sx={{
          borderRadius: "15px",
          padding: "30px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        {alert?.show && (
          <Alert
            variant="filled"
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, show: false })}
            style={{
              position: "fixed",
              top: "25%",
              left: "50%",
              transform: "translateX(-50%)",
              maxWidth: "400px",
              zIndex: 1000,
            }}
          >
            {alert.message}
          </Alert>
        )}

        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <TextField
            label="Policy Name"
            variant="outlined"
            value={policyName}
            onChange={(e) => setPolicyName(e.target.value)}
            fullWidth
            required
            error={!!errors.policyName}
            helperText={errors.policyName}
          />
          <TextField
            label="Policy Description"
            variant="outlined"
            value={policyDescription}
            onChange={(e) => setPolicyDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            required
            error={!!errors.policyDescription}
            helperText={errors.policyDescription}
          />
          <TextField
            label="Policy Start Date"
            type="date"
            variant="outlined"
            value={policyStartDate || ""}
            onChange={(e) => setPolicyStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
            error={!!errors.policyStartDate}
            helperText={errors.policyStartDate}
          />
          <TextField
            label="Policy Expiration Date"
            type="date"
            variant="outlined"
            value={policyExpireDate || ""}
            onChange={(e) => setPolicyExpireDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
            error={!!errors.policyExpireDate}
            helperText={errors.policyExpireDate}
          />

          <FormControl fullWidth required error={!!errors.department}>
            <InputLabel>Department</InputLabel>
            <Select
              value={selectedDepartment} // Matches department_id
              onChange={(e) => setSelectedDepartment(e.target.value)} // Set department_id
              label="Department"
            >
              {departments.length > 0 ? (
                departments.map((dep) => (
                  <MenuItem key={dep.department_id} value={dep.department_id}>
                    {dep.department_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Department available</MenuItem>
              )}
            </Select>
            {errors.department && (
              <Typography color="error" variant="caption">
                {errors.department}
              </Typography>
            )}
          </FormControl>

          <FormControl fullWidth required error={!!errors.type}>
            <InputLabel>Select Policy Type</InputLabel>
            <Select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              label="Select Policy Type"
            >
              {types.length > 0 ? (
                types.map((type) => (
                  <MenuItem key={type.type_id} value={type.type_id}>
                    {type.type_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Policy Types available</MenuItem>
              )}
            </Select>
            {errors.type && (
              <Typography color="error" variant="caption">
                {errors.type}
              </Typography>
            )}
          </FormControl>
          {showEditor && <PolicyEditor documentContent={documentContent} onContentChange={handleContentChange}   setDocumentContent={setDocumentContent}/>}
          {assesmentEditor && (
            <CreateQuestion setAssesmentEditor={setAssesmentEditor} />
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
            sx={{ alignSelf: "flex-start", padding: "10px 20px" }}
          >
            Save
          </Button>
          {loading && (
            <CircularProgress
              size={40}
              sx={{
                color: "blue",
                position: "absolute",
                top: "50%",
                left: "55%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};


export default EditPolicies;
