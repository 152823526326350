import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  TextField,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";

import SearchIcon from "@mui/icons-material/Search";
import ApprovalIcon from "@mui/icons-material/Approval";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

const ApprovalReview = () => {  
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);
  const [openComments, setOpenComments] = useState({});
  const [comments, setComments] = useState({});
  const [approvalAction, setApprovalAction] = useState({});
  const [status, setStatus] = useState([]);

  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("loggedinUserRole");

  useEffect(() => {
    switch (userRole) {
      case "Administrator":
        setStatus([
          "Created",
          "Revision Requested",
          "Assigned to Director",
          "Approved by Quality Director",
        ]);
        // setStatus(["Created", "rejected", "Revision Requested", "Assigned to Director", "Approved by Director", "Approved by Quality Director", "Approved by Senior Leader"]);
        break;
      case "Director":
        setStatus(["Assigned to Director"]);
        break;
      case "Senior Leader":
        setStatus(["Assigned to Senior Leader", "Approved by Director"]);
        break;
      case "Quality Director":
        setStatus([
          "Assigned to Quality Director",
          "Approved by Senior Leader",
        ]);
        break;

      default:
        setStatus([]);
    }
  }, []);

  useEffect(() => {
    if (status.length > 0) {
      const statusParam = status.map(encodeURIComponent).join("&statuses=");
      axiosInstance
        .get(`${ApiUrls.POLICY_API}/by/statuses?statuses=${statusParam}`)
        .then((response) => {
          setPolicies(response.data);
        })
        .catch((error) => {
          console.error("Error fetching policies:", error);
        });
    }
  }, [status]);

  const handleViewPolicy = (policyId) => {
    navigate(`/policydetails?policy_id=${policyId}`);
  };

  const handleViewFlow = (Id) => {
    navigate(`/policyflow?policy_id=${Id}`);
  };

  const handleCommentChange = (id, value) => {
    setComments((prevComments) => ({
      ...prevComments,
      [id]: value,
    }));
  };

  const handleCloseCommentSection = (id) => {
    setOpenComments((prevOpenComments) => ({
      ...prevOpenComments,
      [id]: false,
    }));
  };

  const handleApprove = (policy) => {
    const nextStatus = getNextStatus(policy.policy_status);
    updatePolicyStatus(policy.policy_id, nextStatus);
  };

  const handleRequestRevision = (policy) => {
    setOpenComments((prevOpenComments) => ({
      ...prevOpenComments,
      [policy.policy_id]: true,
    }));
  };

  // const handleSendComment = (policy) => {
  //   updatePolicyStatus(policy.policy_id, "Revision Requested");
  // }; 

  const handleSendComment = (policyId) => {
    const comment = comments[policyId];
    
    if (!comment || comment.trim() === "") {
      alert("Please add a comment before sending.");
      return;
    }
  
    const requestBody = {
      policy_status: "Revision Requested", 
      comment: comment,                   
    };
  
    axiosInstance
    .put(ApiUrls.POLICY_API + "/" + policyId, requestBody)
      .then(() => {
        setPolicies((prevPolicies) =>
          prevPolicies.map((policy) =>
            policy.policy_id === policyId
              ? { ...policy, policy_status: "Revision Requested" }
              : policy
          )
        );
        setOpenComments((prevOpenComments) => ({
          ...prevOpenComments,
          [policyId]: false, // Close the comment section
        }));
        setComments((prevComments) => ({
          ...prevComments,
          [policyId]: "", // Clear the comment field
        }));
      })
      .catch((error) => {
        console.error("Error sending comment:", error);
      });
  };
  

  const handlePublish = (policy) => {
    updatePolicyStatus(policy.policy_id, "Published");
  };

  const updatePolicyStatus = (id, newStatus) => {
    axiosInstance
      .put(`${ApiUrls.POLICY_API}/${id}`, { policy_status: newStatus })
      .then(() => {
        setPolicies((prevPolicies) =>
          prevPolicies.map((policy) =>
            policy.policy_id === id
              ? { ...policy, policy_status: newStatus }
              : policy
          )
        );
      })
      .catch((error) => {
        console.error("Error updating policy status:", error);
      });
  };

  const getNextStatus = (currentStatus) => {
    switch (currentStatus) {
      case "Assigned to Director":
        return "Assigned to Senior Leader";
      case "Assigned to Senior Leader":
        return "Assigned to Quality Director";
      case "Assigned to Quality Director":
        return "Approved by Quality Director";
      default:
        return currentStatus;
    }
  };

  const canApprove = (policy) => {
    switch (userRole) {
      case "Director":
        return policy.policy_status === "Assigned to Director";
      case "Senior Leader":
        return policy.policy_status === "Assigned to Senior Leader";
      case "Quality Director":
        return policy.policy_status === "Assigned to Quality Director";
      default:
        return false;
    }
  };

  const canRequestRevision = (policy) => {
    return (
      userRole !== "Administrator" &&
      policy.policy_status === `Assigned to ${userRole}`
    );
  };

  const canPublish = (policy) => {
    return (
      userRole === "Administrator" &&
      policy.policy_status === "Approved by Quality Director"
    );
  };

  return (
    <div>
      <Typography
        variant="h4"
        sx={{ mb: 3, fontWeight: "bold", color: "#333" }}
      >
        Approval & Review
      </Typography>
      <Paper
        sx={{
          padding: "10px",
          borderRadius: "10px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid black",
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", color: "#555" }}
                  align="center"
                >
                  {" "}
                  Policy Title{" "}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#555" }}>
                  {" "}
                  Policy Status{" "}
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: "#555" }}
                  align="center"
                >
                  {" "}
                  View{" "}
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: "#555" }}
                  align="center"
                >
                  {" "}
                  Actions{" "}
                </TableCell>
                {/* <TableCell sx={{ fontWeight: "bold", color: "#555" }} align="center"> Request Revision </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.map((policy) => (
                <React.Fragment>
                  <TableRow key={policy.policy_id}>
                    <TableCell align="center">{policy.policy_name}</TableCell>
                    <TableCell>{policy.policy_status}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        aria-label="view policy"
                        onClick={() => handleViewPolicy(policy.policy_id)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", gap: "8px" }}>
                        {/* Show Approve Button if user can approve */}
                        {canApprove(policy) && (
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleApprove(policy)}
                          >
                            Approve
                          </Button>
                        )}

                        {/* Show Request Revision Button if user can request revision */}
                        {canRequestRevision(policy) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleRequestRevision(policy)}
                          >
                            Request Revision
                          </Button>
                        )}

                        {/* Show Publish Button if user can publish */}
                        {canPublish(policy) && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handlePublish(policy)}
                          >
                            Publish
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleViewFlow(policy.policy_id)}
                        >
                          View Flow
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>

                  {/* Conditional Comment Section for Approval and Revision */}
                  {openComments[policy.policy_id] && (
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                      >
                        <Collapse
                          in={openComments[policy.policy_id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box
                            sx={{
                              margin: 2,
                              padding: 2,
                              backgroundColor: "#f9f9f9",
                              borderRadius: "8px",
                              position: "relative",
                            }}
                          >
                            <TextField
                              label="Add Comment"
                              fullWidth
                              multiline
                              rows={3}
                              variant="outlined"
                              value={comments[policy.policy_id] || ""}
                              onChange={(e) =>
                                handleCommentChange(
                                  policy.policy_id,
                                  e.target.value
                                )
                              }
                              sx={{ mt: 2 }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="warning"
                                sx={{ mt: 2 }}
                                onClick={() =>
                                  handleCloseCommentSection(policy.policy_id)
                                }
                              >
                                close
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                                onClick={() =>
                                  handleSendComment(policy.policy_id)
                                }
                              >
                                Send
                              </Button>
                            </div>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default ApprovalReview;
