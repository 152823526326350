import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Menu, MenuItem, Box, ListItem, ListItemIcon, ListItemText, Typography, Chip } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { FaDownload, FaTrash } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import { MdAssignment, MdPeople, MdWarning, MdNotificationImportant, MdEvent, MdMessage, MdDownload, MdLocalFireDepartment, MdMedicalServices, MdMedicalInformation, MdLocalHospital, MdLocalActivity, MdEmergency, MdPolicy, MdCancel, MdConfirmationNumber, MdPublish, MdCreate, MdClose, MdCheckCircle } from "react-icons/md";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Alert, Select, Snackbar, ClickAwayListener } from "@mui/material";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PolicyIcon from "@mui/icons-material/Policy";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";


function FlowActions() { 
    useAuthHeaders();
    const [policyData, setPolicyData] = useState({})
    const [actions, setActions] = useState([]);
    const [sortedActions,setSortedActions] = useState([]);
    const [nextActions, setNextActions] = useState([]);
    const [commentOpen, setCommentOpen] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [commentValid, setCommentValid] = useState(false);
    const [doctorValid, setDoctorValid] = useState(false);
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const policyId = searchParams.get("policy_id");

    useEffect(() => {
        fetchPolicyDataById();
    }, []);

    const fetchPolicyDataById = () => {
        axiosInstance.get(ApiUrls.POLICY_API + "/" + policyId, {
        })
            .then(response => {
                setPolicyData(response.data);
                let policyActions = [];
                try {
                    policyActions = Array.isArray(JSON.parse(response.data.policy_actions))
                        ? JSON.parse(response.data.policy_actions) 
                        : [];
                } catch (error) {
                    console.error("Error parsing policy actions:", error);
                }
                setActions(policyActions);
                const sorted = policyActions.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                setSortedActions(sorted);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    // const sortedActions = Array.isArray(actions) ? actions.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)) : [];
    console.log(sortedActions,"sortedActions")

    const handleAssign = () => {

    };

    const handleChangeStatus = () => {

    };

    const handleComment = () => {

    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleCommentOpen = () => {
        setCommentOpen(true);
        setCommentValid(false);
    };

    const handleCommentClose = () => {
        setCommentOpen(false);
        setCommentText("");
    };

    const openDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const cancelDeleteFlow = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleCancelEdit = () => {
        setEditMode(false);
    };


    const getTimelineIcon = (actionType) => {
        switch (actionType) {
            case 'Created':
                return <MdCreate style={{ backgroundColor: "#a3e1a3" }} />;
            case 'Assigned to Director':
                return <MdAssignment style={{ backgroundColor: "#a3e1a3" }} />;
            case 'Assigned to Senior Leader':
                return <MdAssignment style={{ backgroundColor: "blue" }} />;
            case 'Assigned to Quality Director':
                return <MdPolicy style={{ backgroundColor: "blue" }} />;
            case 'Approved':
                return <MdCheckCircle style={{ backgroundColor: "blue" }} />;
            case 'Published':
                return <MdPublish style={{ backgroundColor: "green" }} />;
            case 'Revision Requested':
                return <MdWarning style={{ backgroundColor: "yellow" }} />;
            case 'Rejected':
                return <MdCancel style={{ backgroundColor: "red" }} />;
            case 'Expired':
                return <MdLocalFireDepartment style={{ backgroundColor: "gray" }} />;
            case 'Approved by Director':
                return <MdCheckCircle style={{ backgroundColor: "blue" }} />;
            case 'Approved by Senior Leader':
                return <MdCheckCircle style={{ backgroundColor: "blue" }} />;
            case 'Approved by Quality Director':
                return <MdCheckCircle style={{ backgroundColor: "blue" }} />;
            default:
                return null;
        }
    };


    // const renderEventDetails = (action, index) => {
    //     switch (action.action_type) {

    //         case 'status_change':
    //             return (
    //                 <>
    //                     <h3>Status </h3>

    //                     <div>

    //                         <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                             <div><p>{action.status}</p></div>
    //                         </div>


    //                     </div>
    //                 </>
    //             );

    //         case 'assign_doctor':
    //             return (
    //                 <>
    //                     <h3 style={{ color: action.is_deleted ? 'red' : 'inherit' }}>Assigned To Doctor</h3>
    //                     {editMode && editingIndex === index ? (
    //                         <div>
    //                             <TextField
    //                                 rows={4}
    //                                 fullWidth
    //                                 style={{ marginBottom: "10px" }}
    //                                 defaultValue={action.assigned_to_name}
    //                                 value={editedDoctorData}
    //                                 onChange={(e) => setEditedDoctorData(e.target.value)}
    //                             />
    //                             <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdateDoctor(action.incident_activity_id, 'assig_doctor')}>Update</Button>
    //                             <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
    //                         </div>
    //                     ) : (
    //                         <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                             <div><p>{action.assigned_to_name}</p></div>
    //                             <div>
    //                                 {validWorkflowsEdit.includes(accessInfo?.workflow) && (
    //                                     <BorderColorIcon onClick={() => toggleEditdoctor(index, action)} />
    //                                 )}
    //                                 {validWorkflowsDelete.includes(accessInfo?.workflow) && (
    //                                     <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
    //                                 )}
    //                             </div>
    //                         </div>
    //                     )}
    //                 </>

    //             );

    //         case 'comment':
    //             return (
    //                 <>
    //                     <h3>Comment Added</h3>

    //                     <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                         <div><p>{action.comment}</p></div>
    //                     </div>

    //                 </>
    //             );
    //         case 'assign_primary_department':
    //             return (
    //                 <>
    //                     <h3>Assigned To Department</h3>
    //                     <div>
    //                         {editPrimaryDeptMode ? (
    //                             <div>
    //                                 <Select
    //                                     value={editedPirmaryDepartmentData}
    //                                     onChange={(e) => setEditedPrimaryDepartmentData(e.target.value)}
    //                                     fullWidth
    //                                     style={{ marginBottom: "10px" }}
    //                                 >
    //                                     {department.map(dept => (
    //                                         <MenuItem key={dept.department_id} value={dept.department_id}>
    //                                             {dept.department_name}
    //                                         </MenuItem>
    //                                     ))}
    //                                 </Select>

    //                                 <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdatePrimaryDepartment(action.incident_activity_id, 'assign_primary_department')}>Update</Button>
    //                                 <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEditPrimaryDept}>Cancel</Button>
    //                             </div>
    //                         ) : (
    //                             <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                                 <div><p>{action.department_name}</p></div>
    //                                 <div>
    //                                     {validWorkflowsEdit.includes(accessInfo?.workflow) && (
    //                                         <BorderColorIcon onClick={() => setEditPrimaryDeptMode(true)} />
    //                                     )}
    //                                     {validWorkflowsDelete.includes(accessInfo?.workflow) && (
    //                                         <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
    //                                     )}
    //                                 </div>
    //                             </div>
    //                         )}
    //                     </div>
    //                 </>


    //             );

    //         case 'assign_secondary_department':
    //             return (
    //                 <>
    //                     <h3>Assigned Secondary Department</h3>
    //                     <div>
    //                         {editSecDeptMode ? (
    //                             <div>
    //                                 <Select
    //                                     value={editedSecondaryDepartmentData}
    //                                     onChange={(e) => setEditedSecondaryDepartmentData(e.target.value)}
    //                                     fullWidth
    //                                     style={{ marginBottom: "10px" }}
    //                                 >
    //                                     {department.map(dept => (
    //                                         <MenuItem key={dept.department_id} value={dept.department_id}>
    //                                             {dept.department_name}
    //                                         </MenuItem>
    //                                     ))}
    //                                 </Select>
    //                                 <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdateSecondaryDepartment(action.incident_activity_id, 'assign_secondary_department')}>Update</Button>
    //                                 <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEditSecondaryDept}>Cancel</Button>
    //                             </div>
    //                         ) : (
    //                             <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                                 <div><p>{action.department_name}</p></div>

    //                                 <div>
    //                                     {validWorkflowsEdit.includes(accessInfo?.workflow) && (
    //                                         <BorderColorIcon onClick={() => setEditSecDeptMode(true)} />
    //                                     )}
    //                                     {validWorkflowsDelete.includes(accessInfo?.workflow) && (
    //                                         <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
    //                                     )}
    //                                 </div>
    //                             </div>
    //                         )}
    //                     </div>
    //                 </>
    //             );
    //         case 'assign_tertiary_department':
    //             return (
    //                 <>
    //                     <h3>Assigned Tertiary Department</h3>
    //                     <div>
    //                         {editTertiarytDeptMode ? (
    //                             <div>
    //                                 <Select
    //                                     value={editedTertiaryDepartmentData}
    //                                     onChange={(e) => setEditedTertiaryDepartmentData(e.target.value)}
    //                                     fullWidth
    //                                     style={{ marginBottom: "10px" }}
    //                                 >
    //                                     {department.map(dept => (
    //                                         <MenuItem key={dept.department_id} value={dept.department_id}>
    //                                             {dept.department_name}
    //                                         </MenuItem>
    //                                     ))}
    //                                 </Select>
    //                                 <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdateTertiaryDepartment(action.incident_activity_id, 'assign_tertiary_department')}>Update</Button>
    //                                 <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEditTrtiaryDept}>Cancel</Button>
    //                             </div>
    //                         ) : (
    //                             <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                                 <div><p>{action.department_name}</p></div>

    //                                 <div>
    //                                     {validWorkflowsEdit.includes(accessInfo?.workflow) && (
    //                                         <BorderColorIcon onClick={() => setEditTertiaryDeptMode(true)} />
    //                                     )}
    //                                     {validWorkflowsDelete.includes(accessInfo?.workflow) && (
    //                                         <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
    //                                     )}
    //                                 </div>
    //                             </div>
    //                         )}
    //                     </div>
    //                 </>
    //             );
    //         case 'assign_quarternary_department':
    //             return (
    //                 <>
    //                     <h3> Quarternary Department</h3>
    //                     <div>
    //                         {editQuartDeptMode ? (
    //                             <div>
    //                                 <Select
    //                                     value={editedQuarternaryDepartmentData}
    //                                     onChange={(e) => setEditedQuarternaryDepartmentData(e.target.value)}
    //                                     fullWidth
    //                                     style={{ marginBottom: "10px" }}
    //                                 >
    //                                     {department.map(dept => (
    //                                         <MenuItem key={dept.department_id} value={dept.department_id}>
    //                                             {dept.department_name}
    //                                         </MenuItem>
    //                                     ))}
    //                                 </Select>
    //                                 <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} variant="outlined" onClick={() => handleUpdateQuarternaryDepartment(action.incident_activity_id, 'assign_quarternary_department')}>Update</Button>
    //                                 <Button style={{ backgroundColor: "#34425a", color: "white" }} variant="outlined" onClick={handleCancelEditQuarternaryDept}>Cancel</Button>
    //                             </div>
    //                         ) : (
    //                             <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                                 <div><p>{action.department_name}</p></div>

    //                                 <div>
    //                                     {validWorkflowsEdit.includes(accessInfo?.workflow) && (
    //                                         <BorderColorIcon onClick={() => setEditQuartDeptMode(true)} />
    //                                     )}
    //                                     {validWorkflowsDelete.includes(accessInfo?.workflow) && (
    //                                         <FaTrash style={{ marginLeft: '10px' }} variant="outlined" onClick={() => openDeleteConfirmation(action.incident_activity_id)} />
    //                                     )}
    //                                 </div>
    //                             </div>
    //                         )}
    //                     </div>
    //                 </>
    //             );

    //         default:
    //             return null;
    //     }
    // };

    return (
        <div className="page-content">
            
            <div className="page-inner">
            <Typography variant="h5" sx={{display:"flex", justifyContent:"center", color: "#3f6677" }}>
                     Approval Flow
             </Typography>
                <ClickAwayListener onClickAway={() => { }}>
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={2000}
                        onClose={() => setSnackbar({ ...snackbar, open: false })}
                        style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} style={{ width: '300px', height: '55px', fontSize: '12px' }}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                </ClickAwayListener>
                <div id="main-wrapper">
                    <div className="row">
                        <div className="col-md-12">

                            <section id="cd-timeline" className="cd-container">
                                {sortedActions.map((action, index) => (
                                    <div key={index} className="cd-timeline-block">
                                        <div className="cd-timeline-img">
                                        {getTimelineIcon(action.policy_status)}
                                        </div>
                                        <div className="cd-timeline-content">
                                            <h3>{action.policy_status}</h3>

                                            <ListItem
                                                sx={{
                                                    backgroundColor: "#f9f9f9",
                                                    borderRadius: "10px",
                                                    mb: 2,
                                                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                                                    padding: "16px",
                                                }}
                                            >
                                                {/* <ListItemIcon>
                                                    <PolicyIcon color="primary" />
                                                </ListItemIcon> */}
                                                <ListItemText
                                                    primary={
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
                                                                Action by: {action.created_by_name}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <>
                                                            <div style={{
                                                                        ml: 0,
                                                                        backgroundColor: "#eceacc",
                                                                        color: "rgb(115 164 158)",
                                                                        fontWeight: "bold",
                                                                        borderRadius:"10px"
                                                                    }}>
                                                                    {`comment- ${action.comments}`}
                                                                   
                                                                   
                                                            </div>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                            <span className="cd-date">
                                                <p>
                                                    <Chip
                                                        label={new Date(action.created_at).toLocaleString()}
                                                        size="small"
                                                        sx={{
                                                            ml: 2,
                                                            backgroundColor: "#e0f7fa",
                                                            color: "#00796b",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                ))}

                                {/* <Dialog open={deleteConfirmationOpen} onClose={cancelDeleteFlow}>
                                    <DialogTitle><h3>Delete Confirmation</h3></DialogTitle>
                                    <DialogContent>
                                        <h5>Are you to want to delete this activity?</h5>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button style={{ backgroundColor: "#34425a", color: "white" }} color="primary">Cancel</Button>
                                        <Button style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }} color="primary" autoFocus>Delete</Button>

                                    </DialogActions>
                                </Dialog> */}

                            </section>


                            {/* <div class="panel-body">
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                    <div className="option">
                                        <div className="dropdown">
                                            <Button
                                                variant="outlined"
                                                style={{ backgroundColor: "#5a8a9a", fontSize: "10px", color: "white" }}
                                            >
                                                Assign To Director
                                            </Button>
                                            <Dialog>
                                                <DialogTitle>Assign To Director</DialogTitle>
                                                <DialogContent>
                                                    <TextField
                                                        placeholder="Enter  comment here..."
                                                        rows={4}
                                                        fullWidth
                                                        style={{ marginBottom: "10px" }}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }}
                                                        disabled={!doctorValid}
                                                        onClick={handleAssign}
                                                    >
                                                        Assign
                                                    </Button>
                                                    <Button
                                                        style={{ backgroundColor: "#34425a", color: "white" }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </div>

                                    <div className="option">
                                        <div className="dropdown">
                                            <Button
                                                variant="outlined"
                                                style={{ backgroundColor: "#5a8a9a", fontSize: "10px", color: "white" }}
                                            >
                                                Assign To Quality Leader
                                            </Button>
                                            <Dialog>
                                                <DialogTitle>Assign To Quality Leader</DialogTitle>
                                                <DialogContent>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        style={{ backgroundColor: "#5a8a9a", color: "white", marginRight: "10px" }}
                                                        disabled={!doctorValid}
                                                        onClick={handleAssign}
                                                    >
                                                        Assign
                                                    </Button>
                                                    <Button
                                                        style={{ backgroundColor: "#34425a", color: "white" }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </div>

                                    <div className="option">
                                        <div className="dropdown">
                                            <Button
                                                variant="outlined"
                                                style={{ backgroundColor: "#5a8a9a", fontSize: "10px", color: "white" }}
                                            >
                                                Request Revision
                                            </Button>
                                            <Dialog>
                                                <DialogTitle> Request Revision</DialogTitle>
                                                <DialogContent>
                                                    <TextField
                                                        value={""}
                                                        placeholder="Enter Comment name..."
                                                        rows={4}
                                                        fullWidth
                                                        style={{ marginBottom: "10px" }}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        style={{ backgroundColor: "#22baa0", color: "white", marginRight: "10px" }}
                                                        disabled={!doctorValid}
                                                        onClick={handleAssign}
                                                    >
                                                        Confirm
                                                    </Button>
                                                    <Button
                                                        style={{ backgroundColor: "#34425a", color: "white" }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </div>
                                    <div className="option">
                                        <div className="dropdown">
                                            <Button variant="outlined" style={{ backgroundColor: "#5a8a9a", color: "white", fontSize: "10px" }} onClick={handleCommentOpen}>Add Comment</Button>
                                            <Dialog open={commentOpen} onClose={handleCommentClose}>
                                                <DialogTitle>Add Comment</DialogTitle>
                                                <DialogContent>
                                                    <TextField
                                                        value={commentText}
                                                        onChange={(e) => {
                                                            setCommentText(e.target.value);
                                                            setCommentValid(!!e.target.value);
                                                        }}
                                                        placeholder="Type your comment here..."
                                                        rows={4}
                                                        fullWidth
                                                        style={{ marginBottom: "10px" }}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button style={{ backgroundColor: "#5a8a9a", color: "white" }} disabled={!commentValid} onClick={handleComment}>Add</Button>
                                                    <Button onClick={handleCommentClose} style={{ backgroundColor: "#34425a", color: "white" }} color="secondary">Cancel</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default FlowActions;