import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Paper, TextField, Button, MenuItem, Select, InputLabel, FormControl, Alert, CircularProgress, } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import mammoth from "mammoth";
import ConvertApi from "convertapi-js";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import PolicyEditor from "../../Components/TinyMceEditor/TinyMceEditor";
import CreateQuestion from "../../Components/CustomQandA/CustomQandA";

const AddPolicies = () => { 
  useAuthHeaders();
  const [policyName, setPolicyName] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const [policyStartDate, setPolicyStartDate] = useState("");
  const [policyExpireDate, setPolicyExpireDate] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [assesmentInfo, setAssesmentInfo] = useState({})
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [file, setFile] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [assesmentEditor, setAssesmentEditor] = useState(false);
  const [documentContent, setDocumentContent] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });
  
  useEffect(() => {
    axiosInstance.get(ApiUrls.DEPARTMENT_API)
      .then((response) => {
        setDepartments(response.data);
      })
      .catch((error) => {});

    axiosInstance.get(ApiUrls.POLICY_TYPE)
      .then((response) => {
        setTypes(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    axiosInstance.get(ApiUrls.POLICY_TYPE + "/By/departmentId/" + selectedDepartment)
    .then((response) => {
      setTypes(response.data);
    })
    .catch((error) => {});
  }, [selectedDepartment]);

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!policyName) newErrors.policyName = "Policy name is required";
    if (!policyDescription)
      newErrors.policyDescription = "Policy description is required";
    if (!policyStartDate) newErrors.policyStartDate = "Start date is required";
    if (!policyExpireDate)
      newErrors.policyExpireDate = "Expiration date is required";
    if (!selectedDepartment)
      newErrors.department = "Please select a department";
    if (!selectedType) newErrors.type = "Please select a Policy Type";
    if (!file) newErrors.file = "Please choose a file before submit";
    return newErrors;
  };

  const resetForm = () => {
    setPolicyName("");
    setPolicyDescription("");
    setPolicyStartDate("");
    setPolicyExpireDate("");
    setSelectedDepartment("");
    setSelectedType("");
    setFile(null);
    setShowEditor(false);
    setDocumentContent("");
    setAssesmentEditor(false);
    setAssesmentInfo({});
  };
 
  const handleFileUpload = async (file) => {
    setFile(file);
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (fileExtension === "docx") {
      handleDocxUpload(file);
    } else if (fileExtension === "pdf") {
      handlePdfUpload(file);
    } else if (fileExtension === "txt") {
      handleTxtUpload(file);
    } else {
      setAlert({
        message:
          "Unsupported file format. Please upload a .docx, .pdf, or .txt file.",
        severity: "error",
        show: true,
      });
      setTimeout(
        () => setAlert((prevAlert) => ({ ...prevAlert, show: false })),
        3000
      );
    }
  };

  const handleDocxUpload = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    mammoth.convertToHtml({ arrayBuffer })
      .then((result) => {
        const htmlContent = result.value;
        setDocumentContent(htmlContent);
        setShowEditor(true);
      })
      .catch((err) => {
        setAlert({
          message: "Failed to process the word file. Please try another file.",
          severity: "error",
          show: true,
        });
        setTimeout(
          () => setAlert((prevAlert) => ({ ...prevAlert, show: false })),
          2000
        );
      }
    );
  };

  const handlePdfUpload = async (file) => {
    let convertApi = ConvertApi.auth("secret_Zgz9z2Etb9yrHJx2");
    let params = convertApi.createParams();
  
    try {
      // Step 1: Convert PDF to Word document
      params.add("File", file);
      const wordResult = await convertApi.convert("pdf", "docx", params);
      const wordFileUrl = wordResult.files[0].Url;
  
      // Step 2: Fetch the Word file as a Blob
      const response = await fetch(wordFileUrl);
      const wordBlob = await response.blob();
      const wordFile = new File([wordBlob], "converted.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      console.log(wordFile, "wordFile response is")
  
      // Step 3: Convert Word document to HTML
      const arrayBuffer = await wordFile.arrayBuffer();
      mammoth.convertToHtml({ arrayBuffer })
        .then((result) => {
          const htmlContent = result.value; 
          setDocumentContent(htmlContent);
          setShowEditor(true);
        })
        .catch((err) => console.error("Error converting .docx file:", err));
    } catch (error) {
      setAlert({
        message: "Failed to process the PDF file. Please try another file.",
        severity: "error",
        show: true,
      });
      setTimeout(
        () => setAlert((prevAlert) => ({ ...prevAlert, show: false })),
        2000
      );
    }
  };

  const handleTxtUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const textContent = reader.result;
      const htmlContent = `<p>${textContent.replace(/\n/g, "<br>")}</p>`;
      setDocumentContent(htmlContent);
      setShowEditor(true);
    };
    reader.readAsText(file);
  };

  const handleContentChange = (newContent) => {
    setDocumentContent(newContent); 
  };

  const convertHtmlToPdf = async (htmlContent) => {
    const convertApi = ConvertApi.auth("secret_Zgz9z2Etb9yrHJx2");
    const params = convertApi.createParams();
    const htmlBlob = new Blob([htmlContent], { type: "text/html" });
    const file = new File([htmlBlob], "document.html", { type: "text/html" });

    try {
      params.add("File", file);
      const result = await convertApi.convert("html", "pdf", params);
      const pdfUrl = result.files[0].Url;
      return pdfUrl;
      // window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("HTML to PDF conversion error:", error);
    }
  };

  const handleSubmit = async () => {
   
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    setErrors({});
    setAlert(null);
   
    const policyPayload = {
      "policy_name":policyName,
      "policy_description": policyDescription,
      "effective_date":policyStartDate,
      "expiration_date":policyExpireDate,
      "department_id": selectedDepartment,
      "type_id":selectedType,
      "policy_status":"created",
      "created_by": parseInt(sessionStorage.getItem('loggedinUserId')), 
      "assessment_info":assesmentInfo,

    };

    try {
      const { data: resData } = await axiosInstance.post(
        ApiUrls.POLICY_API,
        policyPayload
      );
      if (!resData.policy_id) {
        throw new Error("Policy creation failed. No policy ID returned.");
      }

      if (documentContent) {
        const htmlContent = documentContent;
        const pdfUrl = await convertHtmlToPdf(htmlContent); 
        if (pdfUrl) {
          const pdfFile = await fetch(pdfUrl).then((res) => res.blob());
          const pdfFormData = new FormData();
          const fileStorageInfo = {
            type_id:selectedType,
            department_id:selectedDepartment,
            policy_id:resData.policy_id,
            file_folder: "POLICY_DOCUMENT",
          }
          pdfFormData.append("FileStorageInfo", JSON.stringify(fileStorageInfo));
          const customFileName = `${resData.policy_id}.pdf`
          pdfFormData.append("files", pdfFile, customFileName);
          try {
            const fileStorageResponse = await axiosInstance.post(
              `${ApiUrls.FILESTORAGE_API}/upload`,
              pdfFormData
            );
           
          } catch (uploadError) {
            console.error("File upload failed:", uploadError);
            throw new Error("File upload failed");
          }
        }
      }
      resetForm();
      setShowEditor(false);
      setLoading(false);
      setAssesmentInfo({});
      setAlert({
        message: "Policy added successfully.",
        severity: "success",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        message: "Error while adding Policy. Please try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    }
  };

  return (
    <div>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: "bold", color: "#333" }}>
        Add New Policy
      </Typography>
      <Paper sx={{ borderRadius: "15px", padding: "30px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",}} >
        {alert?.show && (
          <Alert variant="filled" severity={alert.severity} onClose={() => setAlert({ ...alert, show: false })}
            style={{
              position: "fixed",
              top: "25%",
              left: "50%",
              transform: "translateX(-50%)",
              maxWidth: "400px",
              zIndex: 1000,
            }}
          >
            {alert.message}
          </Alert>
        )}

        <Box component="form" sx={{ display: "flex", flexDirection: "column",  gap: "20px",}}>
          <TextField
            label="Policy Name"
            variant="outlined"
            value={policyName}
            onChange={(e) => setPolicyName(e.target.value)}
            fullWidth
            required
            error={!!errors.policyName}
            helperText={errors.policyName}
          />
          <TextField
            label="Policy Description"
            variant="outlined"
            value={policyDescription}
            onChange={(e) => setPolicyDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            required
            error={!!errors.policyDescription}
            helperText={errors.policyDescription}
          />

          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%"}}>
          <TextField
            label="Policy Start Date"
            type="date"
            variant="outlined"
            value={policyStartDate}
            onChange={(e) => setPolicyStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }} 
            inputProps={{
              min: new Date().toISOString().split("T")[0], 
            }}
            fullWidth
            required
            error={!!errors.policyStartDate}
            helperText={errors.policyStartDate} 
            sx={{width:"48%"}}
          />
          <TextField
            label="Policy Expiration Date"
            type="date"
            variant="outlined"
            value={policyExpireDate}
            onChange={(e) => setPolicyExpireDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }} 
            inputProps={{
              min: new Date().toISOString().split("T")[0], 
            }}
            fullWidth
            required
            error={!!errors.policyExpireDate}
            helperText={errors.policyExpireDate} 
            sx={{width:"48%"}}
          /> 
          </div>

          <FormControl fullWidth required error={!!errors.department}>
            <InputLabel>Department</InputLabel>
            <Select
              value={selectedDepartment}
              onChange={handleDepartmentChange}
              label="Department"
            >
              {departments.length > 0 ? (
                departments.map((dep) => (
                  <MenuItem key={dep.department_id} value={dep.department_id}>
                    {dep.department_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Department available</MenuItem>
              )}
            </Select>
            {errors.department && (
              <Typography color="error" variant="caption">
                {errors.department}
              </Typography>
            )}
          </FormControl>
            {selectedDepartment && (
          <FormControl fullWidth required error={!!errors.type}>
            <InputLabel>Select Policy Type</InputLabel>
            <Select
              value={selectedType}
              onChange={handleTypeChange}
              label="Select Policy Type"
            >
              {types.length > 0 ? (
                types.map((type) => (
                  <MenuItem key={type.type_id} value={type.type_id}>
                    {type.type_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Policy Types available for selected department.</MenuItem>
              )}
            </Select>
            {errors.type && (
              <Typography color="error" variant="caption">
                {errors.type}
              </Typography>
            )}
          </FormControl>
          )}
          <div style={{ display: "flex", gap: 15 }}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<CloudUploadIcon />}
              sx={{ alignSelf: "flex-start", padding: "10px 40px" }}
            >
              <input
                type="file"
                accept=".docx,.pdf,.txt"
                onChange={(e) => handleFileUpload(e.target.files[0])}
                hidden
              />
              Choose a file
            </Button>

            <Button
              variant="outlined"
              component="label"
              startIcon={<NoteAltIcon />}
              sx={{ alignSelf: "flex-start", padding: "10px 40px" }}
              onClick={() => setAssesmentEditor(true)}
            >
              Create an assessment
            </Button>
          </div>
          {errors.file && (
            <Typography variant="caption" color="error">
              {errors.file}
            </Typography>
          )}
          {file && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {" "}
              Selected File: {file.name}{" "}
            </Typography>
          )}
          {showEditor && <PolicyEditor documentContent={documentContent} onContentChange={handleContentChange}   setDocumentContent={setDocumentContent}/>}
          {assesmentEditor && (
            <CreateQuestion setAssesmentEditor={setAssesmentEditor} setAssesmentInfo={setAssesmentInfo}/>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
            sx={{ alignSelf: "flex-start", padding: "10px 20px" }}
          >
            Submit
          </Button>
          {loading && (
            <CircularProgress
              size={40}
              sx={{
                color: "blue",
                position: "absolute",
                top: "50%",
                left: "55%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default AddPolicies;
