import React, { useState } from "react";
import { Box, Typography, Paper, Button, Input, Link } from "@mui/material";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import axios from "axios";
import { routers } from "../../Configurations/configurationUI";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import {  useNavigate } from "react-router-dom";

const UserImport = () => {  
  useAuthHeaders();
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const key = "excelbulkupload/User.csv";

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDownloadSampleFile = () => {
    axiosInstance
      .post(ApiUrls.FILESTORAGE_API + "/download/file", {
        key: key,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sample-User.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        setResponseMessage("Error downloading sample file. Please try again.");
      });
  };

  const handleFileUpload = (event) => {
    const formData = new FormData();
    formData.append("file", file);
    axiosInstance
      .post(ApiUrls.USER_API + "/bulk/upload", formData)
      .then((response) => {
        setResponseMessage(response.data.message); 
        setTimeout(() => {
          navigate(routers.userlist);
        }, 2000);
      })
      .catch((error) => {
        setResponseMessage("Error adding Users. Please try again.");
      });
  };

  return (
    <div style={{ flex: 1 }}>
      <Paper
        sx={{
          width: "100%",
          height: "auto",
          padding: "20px",
          margin: "65px 0 3px 10px",
          backgroundColor: "#e3f2fd",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
        >
          Instructions
        </Typography>
        <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
          1. Only CSV files are allowed.
          <br />
          2. The first row should be headers with column names like{" "}
          <em>
            First name, last name, email, create Password, Phone, DOB(MM-DD-YYYY), Location,
            Department, Role.{" "}
          </em>
          .<br />
          3. Ensure all required fields are present in the CSV file.
          <br />
          4. Upload the file in the correct format to avoid errors.
          <br />
          5.{" "}
          <Link href="#" underline="hover" onClick={handleDownloadSampleFile}>
            Download Sample File
          </Link>
        </Typography>

        <div
          style={{
            width: "80%",
            height: "auto",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#bbdefb",
            textAlign: "center",
            margin: "80px 0 20px 60px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
          >
            Bulk User Import
          </Typography>
          <div>
            <Input
              type="file"
              accept=".csv"
              name="select_file"
              data-allowed-file-extensions="csv"
              required=""
              inputProps={{ accept: ".csv" }}
              onChange={(event) => setFile(event.target.files[0])}
              fileName={file.name}
              sx={{ marginBottom: "16px" }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!file}
              sx={{ padding: "10px 20px", fontSize: "16px", ml: 5 }}
              onClick={handleFileUpload}
            >
              Import User
            </Button>
            {responseMessage && (
              <Typography variant="body2">{responseMessage}</Typography>
            )}
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default UserImport;
