import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls"; 

const formatDate = (date) => {
  if (!date) return "-"; 
  const parsedDate = new Date(date);
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const year = parsedDate.getFullYear();
  return `${day}/${month}/${year}`;
};

const AssignCompetencies = () => {  
  useAuthHeaders();
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedCompetency, setSelectedCompetency] = useState("");

  const [user, setUser] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [assignment, setAssignments] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.USER_API)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });

    axiosInstance
      .get(ApiUrls.POLICY_API)
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });

    axiosInstance
      .get(ApiUrls.ASSIGNMENT_API)
      .then((response) => {
        setAssignments(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const handleAssign = () => {
    if (!selectedEmployee || !selectedCompetency) {
      setAlert({
        message: "Please select both  employee and a competency.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
      return;
    }

    const requestBody = {
      policy_id: selectedCompetency.policy_id,
      user_id: selectedEmployee,
      type_id: selectedCompetency.type_id,
      location_id: selectedCompetency.location_id,
      department_id: selectedCompetency.department_id,
      assigned_date: new Date(),
      target_date: selectedCompetency.target_date,
      effective_date: selectedCompetency.effective_date,
      policy_description: selectedCompetency.policy_description,
      created_at: new Date(),
      created_by: sessionStorage.getItem("loginUserId"),
    };

    axiosInstance
      .post(ApiUrls.ASSIGNMENT_API, requestBody)
      .then((response) => {
        const assignedEmployee = user.find(
          (emp) => emp.user_id === selectedEmployee
        );
        const newAssignment = {
          user_id: selectedEmployee,
          first_name: assignedEmployee?.first_name || "Unknown",
          policy_name: selectedCompetency.policy_name,
          assignment_status: "Assigned",
        };

        setAssignments((prevAssignments) => [
          ...prevAssignments,
          newAssignment,
        ]);
        setAlert({
          message: "Assign Competence successfully.",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      })
      .catch((error) => {
        setAlert({
          message: "Assign Competence Failed.",
          severity: "error",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      });
  };

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="bold" color="#5a8a9a">
          Assign Competencies
        </Typography>
      </Box>

      <Paper
        sx={{
          borderRadius: "15px",
          padding: "30px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ mb: 4, display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            select
            label="Select Employee"
            value={selectedEmployee}
            onChange={(e) => setSelectedEmployee(e.target.value)}
            fullWidth
          >
            {user.map((employee) => (
              <MenuItem key={employee.user_id} value={employee.user_id}>
                {employee.first_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Select Competency"
            value={selectedCompetency}
            onChange={(e) => setSelectedCompetency(e.target.value)}
            fullWidth
          >
            {policies.length > 0 ? (
              policies.map((competency) => (
                <MenuItem key={competency.policy_id} value={competency}>
                  {competency.policy_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No policies available</MenuItem>
            )}
          </TextField>

          <Button
            variant="contained"
            color="primary"
            onClick={handleAssign}
            startIcon={<PersonAddIcon />}
          >
            Assign Competency
          </Button>
        </Box>
      </Paper>

      <Box sx={{ mb: 4, mt: 5 }}>
        <Typography variant="h5" fontWeight="bold" color="#5a8a9a">
          Assigned Competencies
        </Typography>
      </Box>
      <Paper
        sx={{
          borderRadius: "15px",
          padding: "30px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        
        <TableContainer component={Paper} sx={{ marginTop: 4 }}>
          <Table>
            <TableHead>
              <TableRow >
                <TableCell sx={{bgcolor:"#64b5f6"}}>Employee Name</TableCell>
                <TableCell sx={{bgcolor:"#64b5f6"}}>Assigned Competency</TableCell> 
                <TableCell sx={{bgcolor:"#64b5f6"}}>Assigned date</TableCell>
                <TableCell sx={{bgcolor:"#64b5f6"}}>Target Date</TableCell> 
                <TableCell sx={{bgcolor:"#64b5f6"}}>Completed Date</TableCell> 
                <TableCell sx={{bgcolor:"#64b5f6"}}>Assessment Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignment.map((employee, index) => (
                <TableRow key={employee.user_id}>
                  <TableCell>{employee.first_name}</TableCell>
                  {/* <TableCell>abhi</TableCell> */}
                  <TableCell>{employee.policy_name}</TableCell>
                  <TableCell>{formatDate(employee.assigned_date)}</TableCell>
                  <TableCell>{formatDate(employee.target_date)}</TableCell>
                  <TableCell>{formatDate(employee.completed_date)}</TableCell>
                  <TableCell>{employee.assessment_status === "NotStarted" ? "Not Started" : employee.assessment_status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default AssignCompetencies;
