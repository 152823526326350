import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {
  Card,
  Typography,
  CardActions,
  Button,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { red } from "@mui/material/colors";

const PolicyList = () => {  
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [favorites, setFavorites] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const typeId = searchParams.get("type_id");

  const userId = sessionStorage.getItem('loggedinUserId');

  useEffect(() => {
    if (typeId) {
      axiosInstance
        .get(ApiUrls.ASSIGNMENT_API+ "/userId/" + typeId)
        .then((response) => {
          const policiesData = response.data;
          setPolicies(policiesData);

          // Update favorites state based on is_favourite from the response
          // const initialFavorites = {};
          // policiesData.forEach((policy) => {
          //   initialFavorites[policy.policy_id] = policy.is_favourite;
          // });
          // setFavorites(initialFavorites);

          setLoading(false);
        })
        .catch(() => {
          setError("An error occurred while fetching the policies.");
          setLoading(false);
        });
    }
  }, [typeId]);

  const toggleStar = async (policy) => {
    try {
      if (!policy?.is_favourite == 1) {
        const requestBody1 = {
          // user_id: userId, 
          // policy_id:policy?.policy_id,
          // created_by: userId,
          // created_at: new Date(),
          is_favourite: true,
        };
        const response =  await axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + policy.assignment_id, requestBody1);
        setPolicies((prevPolicies) =>
          prevPolicies.map((p) =>
            p.assignment_id === policy.assignment_id
              ? { ...p, is_favourite: 1 }
              : p
          )
        );
      } else {
        const requestBody2 = {
          // user_id: userId, 
          // policy_id:policy?.policy_id,
          // created_by: userId,
          // created_at: new Date(),
          is_favourite: false,
        };
        await axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + policy.assignment_id, requestBody2);
        setPolicies((prevPolicies) =>
          prevPolicies.map((p) =>
            p.assignment_id === policy.assignment_id
              ? { ...p, is_favourite:0}
              : p
          )
        );
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const renderFileIcon = (fileType) => (
    <PictureAsPdfIcon sx={{ fontSize: 40, color: "#d32f2f" }} />
  );

  const handleCardClick = (policy) => {
    const policyId= policy.policy_id;
    const assignmentId = policy.assignment_id
    axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId,
       {
        last_visited: new Date()
        }
    )
    .then(()=>{
      navigate(`/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`);
    })
    .catch(()=>{
      navigate(`/policydetails?policy_id=${policyId}&assignment_id=${assignmentId}`);
    })
   
  };

  return (
    <div>
      <h2>{policies[0]?.type_name}</h2>

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress color="primary" size={50}/>
        </Box>
      )}

      {error && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography variant="h5" color="error">
            {error}
          </Typography>
        </Box>
      )}

      {!loading && !error && policies.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography variant="h5" color="textSecondary">
            No policies are assigned from this type.
          </Typography>
        </Box>
      )}

      {!loading && !error && policies.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            padding: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {policies.map((policy) => (
            <Card
              key={policy.assignment_id}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                boxShadow: "none",
                cursor: "pointer",
              }}
              onClick={() => handleCardClick(policy)}
            >
              <Box
                sx={{
                  flexShrink: 0,
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "16px",
                }}
              >
                {renderFileIcon(policy.file_type)}
              </Box>

              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#232f3e",
                    marginBottom: "8px",
                  }}
                >
                  {policy.policy_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginBottom: "8px" }}
                >
                  {policy.policy_description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Expiration Date:</strong>{" "}
                  {new Date(policy.expiration_date).toLocaleDateString()}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  marginLeft: "16px",
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleStar(policy);
                  }}
                >
                  {policy.is_favourite === 1 ? (
                    <StarIcon sx={{ color: "#fdd835" }} />
                  ) : (
                    <StarBorderIcon sx={{ color: "#fdd835" }} />
                  )}
                </IconButton>
                <CardActions sx={{ padding: 0 }}>
                  <Button size="small" variant="text" color="primary">
                    View Details
                  </Button>
                </CardActions>
              </Box>
            </Card>
          ))}
        </Box>
      )}
    </div>
  );
};

export default PolicyList;
