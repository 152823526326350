import { Router } from "./Routes/Routers";


function App() {
  return (
    <div>
      <Router/>
    </div>
  );
}

export default App;
