import React, { useEffect, useState, version } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ApprovalIcon from "@mui/icons-material/Approval";
import Temp from "../../Templats/Template";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { useNavigate } from "react-router-dom";

const PolicyLibrary = () => { 
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.POLICY_API)
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching policies:", error);
      });
  }, []);

  const handleViewPolicy = (policyId) => {
    navigate(`/policydetails?policy_id=${policyId}`);
  };
  const handleEdit = (policyId) => {
    navigate(`/editPolicies?policy_id=${policyId}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Policy Library
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <TextField
            placeholder="Search Policies"
            variant="outlined"
            size="small"
            sx={{ marginRight: "10px" }}
            InputProps={{
              endAdornment: <SearchIcon color="action" />,
            }}
          /> */}
          <Button
            onClick={() => navigate("/addPolicies")}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add New Policy
          </Button>
        </Box>
      </Box>

      {/* Policy Table */}
      <TableContainer
        component={Paper}
        sx={{ marginTop: "20px", width: "100%" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", color: "#3f6677" }}>
                Title
              </TableCell>
              {/* <TableCell sx={{ fontWeight: "bold", color: "#3f6677" }}>Description</TableCell> */}
              <TableCell sx={{ fontWeight: "bold", color: "#3f6677" }}>
                Department
              </TableCell>
              {/* <TableCell sx={{ fontWeight: "bold", color: "#3f6677" }}>
                Version
              </TableCell> */}
              <TableCell sx={{ fontWeight: "bold", color: "#3f6677" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policies
              .filter((policy) => policy.policy_status === "Published")
              .map((policy) => (
                <TableRow key={policy.policy_id} hover>
                  <TableCell>{policy.policy_name}</TableCell>
                  <TableCell>{policy.department_name || "N/A"}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleViewPolicy(policy.policy_id)}
                      aria-label="view"
                      color="primary"
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      onClick={(event) => handleEdit(policy.policy_id)}
                      aria-label="edit"
                      color="success"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PolicyLibrary;
