import React, { useState } from "react";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { useNavigate } from "react-router-dom";
import { routers } from "../../Configurations/configurationUI";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import Temp from "../../Templats/Template";
import LocationsImport from "./LocationsImport";
const AddLocations = () => { 
  useAuthHeaders();
  const userId = parseInt(sessionStorage.getItem("loggedinUserId"));
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    location_name: "",
    city: "",
    state: "",
    country: "",
    address: "",
    location_status: "",
    created_at: new Date(),
    last_updated_at: "",
    created_by: userId,
    last_updated_by: 0,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const validateForm = () => {
    const newErrors = {};
    if (!formData.location_name)
      newErrors.location_name = "Location name is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.address) newErrors.address = "Address is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      axiosInstance
        .post(ApiUrls.LOCATION_API, formData)
        .then((response) => {
          setAlert({
            show: true,
            message: "Location added successfully",
            severity: "success",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
            navigate(routers.locationslist);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error adding user",
            severity: "error",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
          }, 2000);
          setLoading(false);
        });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
      setLoading(false);
    }
  };

  const handleClear = () => {
    setFormData({
      location_name: "",
      city: "",
      state: "",
      country: "",
      address: "",
    });
    setErrors({});
  };

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "500px",
            zIndex: 100000000000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <div
        style={{
          backgroundColor: "#E5F2FF",
          borderRadius: "15px",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>
          <h2
            style={{ fontWeight: "bold", color: "#1D3557", fontSize: "31px" }}
          >
            Adding Locations is Essential.
          </h2>
          <p
            style={{
              backgroundColor: "white",
              borderRadius: "50px",
              padding: "1rem 2rem",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              marginTop: "1rem",
              fontSize: "16px",
              lineHeight: "1.5",
            }}
          >
            Ensure to add locations to streamline the process of managing
            Departments and Locations. This will help in organizing resources
            and managing Departments and Users.
          </p>
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <img
            src="https://www.lifewire.com/thmb/YBQuRMKxxhx3Zb3uJ1x-QOT6VsM=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Maplocation_-5a492a4e482c52003601ea25.jpg"
            alt="Location Image"
            style={{
              width: "50%",
              height: "auto",
              borderRadius: "15px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              objectFit: "cover",
              clipPath: "polygon(19% 0%, 100% 0%, 100% 100%, 0% 100%)",
            }}
          />
        </div>
      </div>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "10px",
          gap: "18px",
          marginTop: "60px",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
          <i
            className="fa fa-map-marker-alt"
            style={{ fontSize: "22px", color: "black", paddingRight: "15px" }}
          />
          Add Locations Here
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            label="Location Name *"
            variant="outlined"
            name="location_name"
            value={formData.location_name}
            onChange={handleChange}
            error={!!errors.location_name}
            helperText={errors.location_name}
            sx={{ width: "48%" }}
          />
          <TextField
            label="City *"
            variant="outlined"
            name="city"
            value={formData.city}
            onChange={handleChange}
            error={!!errors.city}
            helperText={errors.city}
            sx={{ width: "48%" }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            label="State *"
            variant="outlined"
            name="state"
            value={formData.state}
            onChange={handleChange}
            error={!!errors.state}
            helperText={errors.state}
            sx={{ width: "48%" }}
          />
          <TextField
            label="Country *"
            variant="outlined"
            name="country"
            value={formData.country}
            onChange={handleChange}
            error={!!errors.country}
            helperText={errors.country}
            sx={{ width: "48%" }}
          />
        </div>
        <TextField
          label="Address *"
          variant="outlined"
          name="address"
          value={formData.address}
          onChange={handleChange}
          multiline
          rows={1}
          error={!!errors.address}
          helperText={errors.address}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained "
            onClick={handleClear}
            sx={{ backgroundColor: "#E5F2FF", color: "black", width: "48%" }}
          >
            Clear
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ backgroundColor: "#1976d2", color: "#fff", width: "48%" }}
          >
            Submit
          </Button>
        </div>
      </Box>
      <div
        style={{
          marginTop: "60px",
          marginBottom: "10px",
          paddingBottom: "30px",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          Import Locations In Bulk
        </Typography>
        <LocationsImport />
      </div>
    </div>
  );
};

export default AddLocations;
