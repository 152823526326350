import React, { useEffect, useState, version } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ApprovalIcon from "@mui/icons-material/Approval";
import Temp from "../../Templats/Template";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { useNavigate } from "react-router-dom";

const Monitorpolicy = () => {
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch policies from API
    axiosInstance.get(ApiUrls.POLICY_API)
      .then(response => {
        setPolicies(response.data);
      })
      .catch(error => {
        console.error("Error fetching policies:", error);
      });
  }, []);

  const handleViewClick = (Id) => {
    navigate(`/policyflow?policy_id=${Id}`); 
  }; 

  const handleEdit = (policyId) => {
    navigate(`/editPolicies?policy_id=${policyId}`); 
  }; 


  return (
    
        <div> 
          <Typography variant="h4" sx={{ mb: 3, fontWeight: "bold", color: "#333" }}>
                 Monitor Process
              </Typography>
          
            <Paper
              sx={{
                borderRadius: "15px",
                padding: "30px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              }}
            >
              
              <TableContainer component={Paper} sx={{ marginTop: "20px", width: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold", color: "#555" }}>Policy Name</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#555" }}>Department</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#555" }}>Version</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#555" }}>Status</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#555" }} align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {policies.map((policy) => (
                      <React.Fragment key={policy.id}>
                        <TableRow>
                          <TableCell>{policy.policy_name}</TableCell>
                          <TableCell>{policy.department_name || "N/A"}</TableCell>
                          <TableCell>{policy.policy_version}</TableCell>
                          <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {policy.policy_status === "created" ? <ApprovalIcon color="success" /> : <ApprovalIcon color="warning" />}
                      <Typography sx={{ marginLeft: "8px" }}>{policy.policy_status}</Typography>
                    </Box>
                  </TableCell>
                          {/* <TableCell>
                            {policy.status === "Pending Approval" ? (
                              <Chip label="Pending Approval" color="warning" icon={<AccessTimeIcon />} />
                            ) : policy.status === "Accepted" ? (
                              <Chip label="Accepted" color="success" icon={<CheckCircleIcon />} />
                            ) : (
                              <Chip label="Recheck" color="error" icon={<EditIcon />} />
                            )}
                          </TableCell> */}
                          <TableCell>
                    <IconButton  onClick={()=>handleViewClick(policy.policy_id)} aria-label="view" color="primary">
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton  onClick={()=>handleEdit(policy.policy_id)} aria-label="edit" color="secondary">
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                          
                        </TableRow>
                        {/* Comments Section */}
                        
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
      
        </div>
      
  );
};

export default Monitorpolicy;
