import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Navigation from "../../Components/NavigationBar/Navigation";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StarIcon from "@mui/icons-material/Star";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InsertChartIcon from "@mui/icons-material/InsertChart";

const documentsData = [
  { name: "Data_Privacy_Policy_Oct2024.docx", date: "10-10-2024", type: "doc" },
  {
    name: "Compliance_Guidelines_Oct2024.docx",
    date: "11-10-2024",
    type: "xls",
  },
  { name: "AntiBribery_Policy_Oct2024.docx", date: "12-10-2024", type: "xls" },
  {
    name: "Code_Of_Conduct_Employee_Oct2024.docx",
    date: "13-10-2024",
    type: "doc",
  },
  {
    name: "Risk_Assessment_Procedures_Oct2024.docx",
    date: "14-10-2024",
    type: "xls",
  },
  {
    name: "GDPR_Compliance_Overview_Oct2024.docx",
    date: "15-10-2024",
    type: "doc",
  },
  {
    name: "Whistleblower_Protection_Policy_Oct2024.docx",
    date: "16-10-2024",
    type: "xls",
  },
];

const RecentDocuments = () => {
  const [documents, setDocuments] = useState(
    documentsData.map((doc) => ({ ...doc, favorite: false }))
  );

  const toggleFavorite = (index) => {
    const updatedDocuments = [...documents];
    updatedDocuments[index].favorite = !updatedDocuments[index].favorite;
    setDocuments(updatedDocuments);
  };

  return (
    <div style={{ overflow: "hidden", width: "100vw", height: "100vh" }}>
      <Header />
      <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
        <Navigation />
        <div style={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "95px",
              backgroundColor: "#7da1b2",
              minHeight: "100%",
              overflow: "auto",
            }}
          >
            <Paper
              sx={{
                width: "100%",
                maxWidth: "1000px",
                borderRadius: "15px",
                overflow: "auto",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Box
                sx={{
                  padding: "16px",
                  backgroundColor: "#5a8a9a",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold", flex: 1 }}>
                  Recent Documents
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    paddingRight: "4px",
                    fontWeight: "bold",
                  }}
                >
                  {documents.length}
                </Typography>
              </Box>

              <TableContainer
                component={Box}
                sx={{
                  backgroundColor: "#e0ecf1",
                  maxHeight: "400px",
                  overflow: "auto",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold", color: "#3f6677" }}>
                        Name
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", color: "#3f6677" }}
                        align="right"
                      >
                        Accessed On
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: "bold", color: "#3f6677" }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documents.map((doc, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:nth-of-type(odd)": { backgroundColor: "#f2f9fc" },
                        }}
                      >
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {doc.type === "doc" ? (
                              <InsertDriveFileIcon
                                sx={{ color: "#d32f2f", mr: 1 }}
                              />
                            ) : (
                              <InsertChartIcon
                                sx={{ color: "#2e7d32", mr: 1 }}
                              />
                            )}
                            <Typography>{doc.name}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Typography>{doc.date}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="View" arrow>
                            <IconButton aria-label="view document">
                              <VisibilityIcon sx={{ color: "#5a8a9a" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={doc.favorite ? "Unfavorite" : "Favorite"}
                            arrow
                          >
                            <IconButton
                              aria-label="favorite document"
                              onClick={() => toggleFavorite(index)}
                            >
                              <StarIcon
                                sx={{
                                  color: doc.favorite ? "#fdd835" : "#ccc",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default RecentDocuments;
