import React, { useEffect, useState } from "react";
import {Box, Typography, Paper, TextField, Button, MenuItem, Select, InputLabel, FormControl} from "@mui/material";
import { routers } from "../../Configurations/configurationUI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import { Alert } from "@mui/material";

const EditDepartment = () => { 
  useAuthHeaders();
  const [formData, setFormData] = useState({
    department_name: "", 
    department_description : "",
    location_name: "",  
    department_status:"",
  });  

  const [departmentName, setDepartmentName] = useState(""); 
  const [departmentDescription, setDepartmentDescription] = useState("");
  const [locationName, setLocationName] = useState(""); 
  const [status, setStatus] = useState("");

  const [locations, setLocations] = useState([]);
  const [selectlocation, setSelectedLocation] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const departmentId = queryParams.get("department_id");

  useEffect(() => {
    axiosInstance.get(ApiUrls.DEPARTMENT_API + "/" + departmentId)
      .then((response) => {
        const data = response.data; 
        setDepartmentName(data.department_name || ""); 
        setDepartmentDescription(data.department_description || "");  
        setLocationName(data.location_id || "");  
        setStatus(data.department_status || "");

      })
      .catch((error) => {
        // error handle
      });

    axiosInstance.get(ApiUrls.LOCATION_API)
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        // error handle
      });
  }, []); 

  const validateForm = () => {
    const newErrors = {};
    if (!departmentName) newErrors.departmentName = "Name is required";
    if (!departmentDescription)newErrors.departmentDescription = "Description is required";
    if (!locationName) newErrors.locationName= "Location is required";
    if (!status) newErrors.status= "Status is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "department_name") {
      setFormData({ ...formData, [name]: value });
    }  

    if (name === "department_description") {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "location") {
      setFormData({ ...formData, location_id: value });
      setSelectedLocation(value);
    } 
    if (name === "department_status") {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    const updatedFormData = {
      department_name: departmentName, 
      department_description: departmentDescription,
      location_id: locationName,  
      department_status:status,

    };

    if (validateForm()) {
    axiosInstance.put(ApiUrls.DEPARTMENT_API + "/" + departmentId, updatedFormData)
      .then((response) => {
        setAlert({
          show: true,
          message: "Department Updated successfully",
          severity: "success",
        });
       setTimeout(() => 
        {
          setAlert((alert) => ({ ...alert, show: false }));
          navigate(routers.departmentlist);
        }, 2000);
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: "Error Update Department",
          severity: "error",
        });
        console.error("Error Updating Department:", error);
      });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
    }
  };

  return (
      <div>
       {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert> 
       )}
          <Typography variant="h5" sx={{ mb: 8 }}>
                Edit Department
              </Typography>
              <Paper
              sx={{
                
                padding: "20px",
                mt: -5,
                backgroundColor: "#e3f2fd",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                
              }}
            >
              
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  height: "100%",
                  maxHeight: "100%",
                  overflowY: "auto",
                  paddingBottom: "20px",
                  marginTop:"2px"
                }}
                className="custom-scrollbar"
              >
                <TextField
                  label="Department Name"
                  variant="outlined"
                  name="department_name"
                  value={departmentName} 
                  onChange={(e) => setDepartmentName(e.target.value)}
                  error={!!errors.departmentName}
                  helperText={errors.departmentName} 
                  style={{marginTop:"15px"}}
                />

                <TextField
                  label="Department Description"
                  variant="outlined"
                  name="department_description"
                  value={departmentDescription} 
                  onChange={(e) => setDepartmentDescription(e.target.value)} 
                  error={!!errors.departmentDescription}
                  helperText={errors.departmentDescription}
                />

                <FormControl variant="outlined" error={!!errors.locationName}>
                  <InputLabel>Location</InputLabel>
                  <Select
                    label="Location"
                    name="location"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                  >
                    {locations.length > 0 ? (
                      locations.map((loc) => (
                        <MenuItem key={loc.location_id} value={loc.location_id}>
                          {loc.location_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No locations available</MenuItem>
                    )}
                  </Select>
                </FormControl> 

                <TextField
                  label="Department Status"
                  variant="outlined"
                  name="department_status"
                  value={status} 
                  onChange={(e) => setStatus(e.target.value)} 
                  error={!!errors.status}
                  helperText={errors.status}
                />

                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ backgroundColor: "#1976d2", color: "#fff" }}
                >
                  Submit
                </Button>
              </Box>
            </Paper>
          
        </div>
    
  );
};

export default EditDepartment;
