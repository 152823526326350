import React, { useEffect, useState } from "react";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";

const ProfileScreen = () => { 
  useAuthHeaders();
  const userId = sessionStorage.getItem("loggedinUserId");
  const [user, setUser] = useState({}); 
  const userrole = sessionStorage.getItem("loggedinUserRole");

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.USER_API + "/" + userId)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {});
  }, [userId]);

  const name =
    (user.first_name?.charAt(0).toUpperCase() || "") + (user.last_name?.charAt(0).toUpperCase() || "");

  return (
    <div
      style={{
        width: "90%",
        maxWidth: "1000px",
        margin: "20px auto",
        padding: "20px",
        backgroundColor: "#1976d2",
        color: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "50px",
          marginTop:"15px",
          marginBottom:"15px"
        }}
      >
        {/* Profile Picture */}
        <div
          style={{
            width: "150px",
            height: "150px",
            backgroundColor: "#0d47a1",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "28px",
            fontWeight: "bold",
            color: "#fff",
            marginLeft:"100px",
          }}
        > 
        <h1>{name}</h1>
          
        </div>

        {/* Profile Details */}
        <div
          style={{
            flex: 1,
            minWidth: "300px",
          }}
        >
          <h2
            style={{
              margin: 0,
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            {user.first_name + " " + user.last_name}
          </h2>
          <p
            style={{
              margin: "8px 0",
              fontSize: "16px",
              color: "#bbdefb",
            }}
          >
            {user.primary_email}
          </p>   


          <h4
            style={{
              margin: "8px 0",
              fontSize: "16px",
              color: "#bbdefb",
              fontWeight: "bold",
            }}
          >
            {userrole}
          </h4> 
         
          <p
            style={{
              margin: "8px 0",
              fontSize: "14px",
              color: "#e3f2fd",
            }}
          >
            Outstanding! Your dedication to learning is inspirational!
          </p> 
          {/* Department and Location */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          marginTop: "30px",
        //   flexWrap: "wrap",
          gap: "20px", 
        }}
      >
        <div
          style={{
            textAlign: "start",
            flex: "1 1 200px",
          }}
        >
          <h5 style={{ margin: "10px 0", color: "#bbdefb" }}>Department</h5>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <i
              className="fa fa-building"
              style={{
                fontSize: "22px",
                color: "white",
              }}
            />
            <h4 style={{ margin: 0 }}>{user.department_name || "N/A"}</h4>
          </div>
        </div>
        <div
          style={{
            textAlign: "start",
            flex: "1 1 200px",
          }}
        >
          <h5 style={{ margin: "10px 0", color: "#bbdefb" }}>Location</h5>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <i
              className="fa fa-map-marker-alt"
              style={{
                fontSize: "22px",
                color: "white",
              }}
            />
            <h4 style={{ margin: 0 }}>{user.location_name || "N/A"}</h4>
          </div>
        </div>
      </div>
        </div>
      </div>

      
    </div>
  );
};

export default ProfileScreen;
