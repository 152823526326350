import React, { useState } from 'react';

const CreateQuestion = ({setAssesmentInfo,  setAssesmentEditor }) => {
  const [question, setQuestion] = useState('');
  const [displayFormat, setDisplayFormat] = useState('Radio Buttons (1 Column)');
  const [questionType, setQuestionType] = useState('Multiple Choice (Only One Answer)');
  const [answerChoices, setAnswerChoices] = useState(['', '', '']);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [otherAnswerChoice, setOtherAnswerChoice] = useState(false);
  const [commentTextBox, setCommentTextBox] = useState(false);
  const [requireAnswer, setRequireAnswer] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState('');

  const handleAddChoice = (event) => {
    event.preventDefault();
    setAnswerChoices([...answerChoices, '']);
  };

  const handleChoiceChange = (index, value) => {
    const updatedChoices = [...answerChoices];
    updatedChoices[index] = value;
    setAnswerChoices(updatedChoices);
  };

  const handleRemoveChoice = (index) => {
    setAnswerChoices(answerChoices.filter((_, i) => i !== index));
  };

  const validateQuestionsForm = () => {
    if (!question.trim()) {
      setError('Question text is required.');
      return false;
    }
    if (answerChoices.some(choice => !choice.trim())) {
      setError('All answer choices must be filled.');
      return false;
    }
    if (correctAnswer === null) {
      setError('Please select a correct answer.');
      return false;
    }
    setError('');
    return true;
  };

  const handleSaveQuestion = () => {
    if (!validateQuestionsForm()) return;

    const questionData = {
      question,
      displayFormat,
      questionType,
      answerChoices,
      correctAnswer: answerChoices[correctAnswer],
      otherAnswerChoice,
      commentTextBox,
      requireAnswer,
    };

    setQuestions([...questions, questionData]);
    setAssesmentInfo([...questions, questionData]);
    
    setQuestion('');
    setDisplayFormat('Radio Buttons (1 Column)');
    setQuestionType('Multiple Choice (Only One Answer)');
    setAnswerChoices(['', '', '']);
    setCorrectAnswer(null);
    setOtherAnswerChoice(false);
    setCommentTextBox(false);
    setRequireAnswer(false);
  };

  const handleNewQuestion = (event) => {
    event.preventDefault();
    handleSaveQuestion();
  };

  const handleCancel = () => {
    setQuestions([]);
    setAssesmentEditor(false);
  }

//   const handleSubmit = () => {
//     const questionData = {
//       question,
//       displayFormat,
//       questionType,
//       answerChoices,
//       otherAnswerChoice,
//       commentTextBox,
//       requireAnswer
//     };
//     console.log(questionData);
//   };

  return (
    <div style={{
      width: '95%',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#f5f7f9',
      border: '1px solid #d0d5db',
      borderRadius: '8px'
    }}>
      <h2 style={{ color: '#2c3e50' }}>Adding Question {questions.length + 1}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Question</label>
        <input 
          type="text" 
          placeholder="Enter Question Text" 
          value={question} 
          onChange={(e) => setQuestion(e.target.value)} 
          style={{
            width: '100%',
            padding: '8px',
            border: '1px solid #d0d5db',
            borderRadius: '4px',
            marginTop: '5px'
          }}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Display Format</label>
        <select 
          value={displayFormat} 
          onChange={(e) => setDisplayFormat(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            border: '1px solid #d0d5db',
            borderRadius: '4px'
          }}
        >
          <option>Radio Buttons (1 Column)</option>
          <option>Radio Buttons (2 Columns)</option>
          <option>Radio Buttons (3 Columns)</option>
          <option>Radio Buttons (4 Columns)</option>
          <option>Radio Buttons (Horizontal)</option>
          <option>Drop-Down Menu</option>
          <option>Searchable Drop-Down Menu</option>
        </select>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Question Type</label>
        <select 
          value={questionType} 
          onChange={(e) => setQuestionType(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            border: '1px solid #d0d5db',
            borderRadius: '4px'
          }}
        >
          <option>Multiple Choice (Only One Answer)</option>
          <option>Multiple Choice (Multiple Answers)</option>
          <option>Text</option>
          <option>Rating</option>
        </select>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>Answer Choices</label>
        {answerChoices.map((choice, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
            <input
              type="text"
              placeholder="Enter Answer Choice"
              value={choice}
              onChange={(e) => handleChoiceChange(index, e.target.value)}
              style={{ flexGrow: 1, padding: '8px', marginRight: '5px' }}
            />
            <input
              type="radio"
              name="correctAnswer"
              checked={correctAnswer === index}
              onChange={() => setCorrectAnswer(index)}
            />
            <button type="button" onClick={() => handleRemoveChoice(index)} style={{ marginLeft: '5px' }}>-</button>
          </div>
        ))}
        <button  type="button" onClick={handleAddChoice} style={{ marginTop: '10px' }}>Add Choice</button>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <label>
          <input
            type="checkbox"
            checked={otherAnswerChoice}
            onChange={() => setOtherAnswerChoice(!otherAnswerChoice)}
          /> Add 'Other' Answer Choice
        </label>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <label>
          <input
            type="checkbox"
            checked={commentTextBox}
            onChange={() => setCommentTextBox(!commentTextBox)}
          /> Add 'Comment' Text Box
        </label>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label>
          <input
            type="checkbox"
            checked={requireAnswer}
            onChange={() => setRequireAnswer(!requireAnswer)}
          /> Require an Answer to this Question
        </label>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <button  type="button" onClick={handleNewQuestion} style={{ padding: '8px 16px', backgroundColor: '#27ae60', color: 'white' }}>Save Question</button>
        <button type="button" onClick={handleCancel} style={{ padding: '8px 16px', backgroundColor: '#bdc3c7', color: 'white' }}>Cancel</button>
      </div>

      {questions.length > 0 && (
        <>
      <h3 style={{ marginTop: '20px' }}>Saved Questions</h3>
      <div style={{ marginTop: '20px' }}>
        {questions.map((q, index) => (
          <div key={index} style={{ border: '1px solid #ddd', padding: '15px', marginBottom: '10px', borderRadius: '5px' }}>
            <h4 style={{ marginBottom: '10px' }}>Question {index + 1}: {q.question}</h4>
            <p><strong>Display Format:</strong> {q.displayFormat}</p>
            <p><strong>Question Type:</strong> {q.questionType}</p>
            <p><strong>Answer Choices:</strong></p>
            <ul>
              {q.answerChoices.map((choice, i) => (
                <li key={i} style={{ color: q.correctAnswer === choice ? 'green' : 'black' }}>
                  {choice} {q.correctAnswer === choice && '(Correct Answer)'}
                </li>
              ))}
            </ul>
            <p><strong>Other Answer Choice:</strong> {q.otherAnswerChoice ? 'Yes' : 'No'}</p>
            <p><strong>Comment Text Box:</strong> {q.commentTextBox ? 'Yes' : 'No'}</p>
            <p><strong>Require Answer:</strong> {q.requireAnswer ? 'Yes' : 'No'}</p>
          </div>
        ))}
      </div>
      </>
      )}

    </div>
    
     );
};

export default CreateQuestion;
