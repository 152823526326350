export const routers = {
login:"/login", 
home:"/home", 
homeSlider:"homeSlider",
browseManuals:"/browseManuals",
favourites:"/favourites", 
recentDocuments:"recentDocuments", 
reports:"/reports",
competencies:"/competencies",
policyLibrary:"/policyLibrary",
addPolicies:"/addPolicies", 
editPolicies:"/editPolicies", 
monitorProcess:"/monitorProcess",
stayAlert:"/stayAlert",
assignCompetencies:"/assignCompetencies", 
approvalReview:"/approvalReview",
addLogo:"/addLogo", 
editConfiguration:"/editConfiguration",
userImport:"/userImport",
audit:"/audit",
userlist:"/userlist",
adddepartment:"/adddepartment",
addlocations:"/addlocations", 
departmentimport:"/departmentimport", 
locationsimport:"/locationsimport", 
adduser:"/adduser", 
departmentlist:"/departmentlist",
locationslist:"/locationslist", 
edituser:"/edituser",
editdepartment:"/editdepartment", 
editlocation:"/editlocation",
mysettings:"/mysettings", 
policyflow:"/policyflow",
policylist:"/policylist",
addtype:"/addtype",
edittype:"edittype",
policytypes:"/policytypes",
policydetails:"/policydetails", 
searchdetails:"/searchresults",
profilescreen:"/profilescreen", 
forgotpassword:"/forgotpassword",
}

export const languages = ["English", "Bulgarian", "Hindi", "Chinese"];

export const tabs = {
    navigation: {
        [languages[0]]: "Navigation",
        [languages[1]]: "Навигация",
        [languages[2]]: "नेविगेशन",
        [languages[3]]: "Dashboard"
    },
    home: {
        [languages[0]]: "home",
        [languages[1]]: "home",
        [languages[2]]: "home",
        [languages[3]]: "home"
    },
    browseManuals: {
        [languages[0]]: "browseManuals",
        [languages[1]]: "browseManuals ",
        [languages[2]]: "browseManuals",
        [languages[3]]: "browseManuals"
    },
    favourites: {
        [languages[0]]: "favourites",
        [languages[1]]: "favourites",
        [languages[2]]: "favourites",
        [languages[3]]: "favourites"
    },
    reports: {
        [languages[0]]: "reports",
        [languages[1]]: "reports",
        [languages[2]]: "reports",
        [languages[3]]: "reports"
    },
    Roles: {
        [languages[0]]: "Roles",
        [languages[1]]: "Roles",
        [languages[2]]: "Roles",
        [languages[3]]: "Roles"

    },
    UserManagement: {
        [languages[0]]: "User Management",
        [languages[1]]: "User Management",
        [languages[2]]: "User Management",
        [languages[3]]:"User Management"

    },
    
    
    Profile: {
        [languages[0]]: "Profile",
        [languages[1]]: "Profile",
        [languages[2]]: "Profile",
        [languages[3]]: "Profile"

    },
    Locations: {
        [languages[0]]: "Locations",
        [languages[1]]: "Locations",
        [languages[2]]: "Locations",
        [languages[3]]: "Locations"

    },
    locationslist: {
        [languages[0]]: "locationslist",
        [languages[1]]: "locationslist",
        [languages[2]]: "locationslist",
        [languages[3]]: "locationslist"
    },
    addLocation: {
        [languages[0]]: "addLocation",
        [languages[1]]: "addLocation",
        [languages[2]]: "addLocation",
        [languages[3]]: "addLocation"
    },
    Departments: {
        [languages[0]]: "Departments",
        [languages[1]]: "Departments",
        [languages[2]]: "Departments",
        [languages[3]]: "Departments"

    },
    PermissionsetList: {
        [languages[0]]: "Permissionset List",
        [languages[1]]: "Permissionset List ",
        [languages[2]]: "Permissionset List",
        [languages[3]]: "Permissionset List"

    },
    ViewPermissionSet: {
        [languages[0]]: "ViewPermissionset",
        [languages[1]]: "ViewPermissionset ",
        [languages[2]]: "ViewPermissionset",
        [languages[3]]: "ViewPermissionset"

    },
    AddPermissions:{
        [languages[0]]: "AddPermissions",
        [languages[1]]: "AddPermissions",
        [languages[2]]: "AddPermissions",
        [languages[3]]: "AddPermissions"
    },
    EditPermissions: {
        [languages[0]]: "EditPermissions",
        [languages[1]]: "EditPermissions",
        [languages[2]]: "EditPermissions",
        [languages[3]]: "EditPermissions"

    },
    UpdateuserPermission: {
        [languages[0]]: "UpdateuserPermission",
        [languages[1]]: "UpdateuserPermission ",
        [languages[2]]: "UpdateuserPermission",
        [languages[3]]: "UpdateuserPermission"

    },
   
    IncidentAudit: {
        [languages[0]]: "IncidentAudit",
        [languages[1]]: " IncidentAudit",
        [languages[2]]: " IncidentAudit",
        [languages[3]]: " IncidentAudit"
    },

    Reports: {
        [languages[0]]: "Reports",
        [languages[1]]: " Reports",
        [languages[2]]: " Reports",
        [languages[3]]: " Reports"
    },
    Audit: {
        [languages[0]]: "Audit",
        [languages[1]]: "Audit",
        [languages[2]]: "Audit",
        [languages[3]]: "Audit"
    },
    User: {
        [languages[0]]: "User",
        [languages[1]]: "User",
        [languages[2]]: "User",
        [languages[3]]: "User"
    },
    Location: {
        [languages[0]]: "Location",
        [languages[1]]: "Location",
        [languages[2]]: "Location",
        [languages[3]]: "Location"
    },
    Department: {
        [languages[0]]: "Department",
        [languages[1]]: "Department",
        [languages[2]]: "Department",
        [languages[3]]: "Department"
    },
    Permissionset: {
        [languages[0]]: "Permissionset",
        [languages[1]]: "Permissionset",
        [languages[2]]: "Permissionset",
        [languages[3]]: "Permissionset"
    },
    Incidents: {
        [languages[0]]: "Incidents",
        [languages[1]]: "Incidents",
        [languages[2]]: "Incidents",
        [languages[3]]: "Incidents"
    },
    

  
};
