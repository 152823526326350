import { routers } from "../Configurations/configurationUI";

export const getNotificationTitle = (notification) => {
    switch (notification.user_type) {
      case "PolicyCreated": return "A New Policy has been created"
      case "PolicyStatusUpdated": return "Policy has been updated" 
      case "PolicyAssigned": return "A Policy has been assigned for you." 
      default: return "";
    }
  };


  export const getnotificationNavigation = (notification) => {
    switch (notification.user_type) {
      case "PolicyCreated": return [routers.approvalReview];
      case "PolicyStatusUpdated": return [routers.monitorProcess];
      case "PolicyAssigned": return [routers.policydetails, { policy_id: notification.context.policyId }];
      default: return "";
    }
  };