import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    isSidebarOpen:true,
    roleInfo:{},
    loggedinUserRole:"",
    profileInfo:{},
    loggedinUser:{},
    loggedinUserEmail:{},
    JWTToken:""
    
}

const commonSlice = createSlice({
    name: "common",
    initialState: initialState,
    reducers: {
        setIsSidebarOpen: (state, action) => {
            state.isSidebarOpen=action.payload
        },
            setRoleInfo: (state, action) => {
                state.roleInfo=action.payload
            },
            setLoggedinUserRole: (state, action) => {
                state.loggedinUserRole=action.payload
            },
            setProfileInfo: (state, action) => {
                state.roleInfo=action.payload
            },
            setLoggedinUser: (state, action) => {
                state.loggedinUser=action.payload
            },
            setLoggedinUserEmail: (state, action) => {
                state.loggedinUserEmail=action.payload
            },
            setJwtToken: (state, action) => {
                state.JWTToken=action.payload
            },

    }
})

export const commonActions = commonSlice.actions
export default commonSlice