import React, { useEffect, useState } from "react";
import { Typography, Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
   Switch, Snackbar, Alert, CircularProgress,IconButton, InputAdornment
} from "@mui/material";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls"; 
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Settings = () => { 
  useAuthHeaders();
  const [user, setUser] = useState({});
  const [config, setConfig] = useState({
    notificationsEnabled: false,
    emailNotifications: false,
    darkMode: true,
  }); 

  const [openDialog, setOpenDialog] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });  

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  }); 
  const [showPassword, setShowPassword] = useState(false);
  const [conformPassword, setConformPassword] = useState(false);
  const [error, setError] = useState("");
  const [otpMessage, setOtpMessage] = useState(""); 
  

  const userEmail = sessionStorage.getItem("loggedinUserEmail");
  const userId = sessionStorage.getItem("loggedinUserId");


  const handleDialogOpen = () => {
    setOpenDialog(true);
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
    setPasswordForm({
      otp: "",
      newPassword: "",
      confirmPassword: "",
    });
    setError("");
    setOtpMessage("");
  };

  useEffect(() => { 
    setLoading(true);
    axiosInstance
      .get(ApiUrls.USER_API + "/" + userId)
      .then((response) => {
        const userData = response.data;
        setUser(userData);

        setConfig({
          notificationsEnabled: userData.app_notification === 1,
          emailNotifications: userData.email_notification === 1,
        }); 
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false); 
        setAlert({
          message: "Failed to fetch user data. Please try again later.",
          severity: "error",
          show: true,
        });
      });
  }, [userId]);

  const handleFormChange = (field) => (event) => {
    setPasswordForm({ ...passwordForm, [field]: event.target.value });
  };

  const handleOtpChange = (field) => (event) => {
    const numericText = event.target.value.replace(/[^0-9]/g, '');
    setPasswordForm({ ...passwordForm, [field]:numericText });
 };

  const handleGenerateOtp = async () => { 
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiUrls.USER_API + `/generate/otp?email=${encodeURIComponent(userEmail)}`);
      if (response) {
        setOtpMessage("OTP sent successfully to your email."); 
      } else {
        const message =
          typeof response.data.message === "string"
            ? response.data.message
            : "Failed to generate OTP.";
        setOtpMessage(message);
      }
    }  catch (err) {
      console.error("Error generating OTP:", err);
      const message =
        typeof err.response?.data?.message === "string"
          ? err.response.data.message
          : "An error occurred while generating the OTP.";
      setOtpMessage(message);
      setAlert({
        message,
        severity: "error",
        show: true,
      });
    } finally {
      setLoading(false);
    }
  };


  const handlePasswordSubmit = async () => {
    const { otp, newPassword, confirmPassword } = passwordForm;
  
    if (!otp || !newPassword || !confirmPassword) {
      setError("All fields are required.");
      return;
    }
  
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }
  
    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters.");
      return;
    }
  
    setLoading(true);
    try {
      const response = await axiosInstance.put( ApiUrls.USER_API + "/withotp/email/updatepassword",
        {
          primary_email: userEmail,
          otp: otp,
          new_password: newPassword,
        }
      );
      if (response) {
        axiosInstance.put(ApiUrls.USER_API + "/" + userId, 
        {
          password_lastupdated_at:  new Date()
        })
        setOpenDialog(false);
        setAlert({
          message: "Password changed successfully!",
          severity: "success",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      } else {
        const message =
          typeof response.data.message === "string"
            ? response.data.message
            : "Failed to change password.";
        setError(message);
        setAlert({
          message,
          severity: "error",
          show: true,
        });
        setTimeout(() => {
          setAlert((alert) => ({ ...alert, show: false }));
        }, 2000);
      }
    } catch (err) {
      console.error("Error changing password:", err);
      const message =
        typeof err.response?.data?.message === "string"
          ? err.response.data.message
          : "An error occurred while changing the password.";
      setError(message);
      setAlert({
        message,
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    } finally {
      setLoading(false);
    }
  };
  

  const handleSwitchChange = (field) => async (event) => {
    const updatedValue = event.target.checked;
    setConfig((prevConfig) => ({ ...prevConfig, [field]: updatedValue }));
    const updateData = {
      [field === "notificationsEnabled"
        ? "app_notification"
        : "email_notification"]: updatedValue,
    };

    setLoading(true);
    try {
      const response = await axiosInstance.put(`${ApiUrls.USER_API}/${userId}`, updateData);
      setAlert({
        message: "Settings updated successfully!",
        severity: "success",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
     
    } catch (error) {
      console.error("Error updating toggle:", error);

      
      setConfig((prevConfig) => ({ ...prevConfig, [field]: !updatedValue }));
      setAlert({
        message: "Failed to update setting. Please try again.",
        severity: "error",
        show: true,
      });
      setTimeout(() => {
        setAlert((alert) => ({ ...alert, show: false }));
      }, 2000);
    } finally {
      setLoading(false); 
    }
  }; 

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState); 
  }; 

  const toggleConformPasswordVisibility = () => {
    setConformPassword((prevState) => !prevState); 
  };

  const formatDate = (date) => new Date(date).toLocaleString();

  return (
    <div> 
      {loading && (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <CircularProgress />
        </div>
      )}
      <Typography
        variant="h4"
        sx={{ mb: 3, fontWeight: "bold", color: "#333" }}
      >
        My Settings
      </Typography> 
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <Paper
        sx={{
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid black",
        }}
      >
        <Paper
          sx={{
            padding: "20px",
            marginBottom: "20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h6" sx={{ color: "#555", fontWeight: "bold" }}>
            Password
          </Typography>
          <Typography sx={{ color: "#777", mt: 1 }}>
            Your password was last changed on {formatDate(user.password_lastupdated_at) || "N/A"}
          </Typography>
          <Button
            variant="text"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleDialogOpen}
          >
            Change password
          </Button>
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleGenerateOtp}
                  sx={{ width: 250, height: 56 }}
                >
                  Generate OTP
                </Button>

                <TextField
                  label="OTP"
                  keyboardType="numeric"
                  autoCapitalize="none"
                  returnKeyType="done"
                  autoCorrect={false}
                  type="password"
                  variant="outlined"
                  margin="normal"
                  value={passwordForm.otp}
                  onChange={handleOtpChange("otp")}
                  sx={{ width: 280 }}
                  InputProps={{ style: { height: 56 } }}
                  inputProps={{ maxLength: 6 }}
                />
              </div>
              {otpMessage && (
                <Typography color="primary" style={{ marginLeft: "16px" }}>
                  {otpMessage}
                </Typography>
              )}
              <TextField
                fullWidth
                label="New Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                margin="normal"
                value={passwordForm.newPassword}
                onChange={handleFormChange("newPassword")}
                disabled={!passwordForm.otp || passwordForm.otp.length === 0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Confirm New Password"
                type={conformPassword ? "text" : "password"}
                variant="outlined"
                margin="normal"
                value={passwordForm.confirmPassword}
                onChange={handleFormChange("confirmPassword")}
                disabled={!passwordForm.otp || passwordForm.otp.length === 0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleConformPasswordVisibility} edge="end">
                        {conformPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {error && <Typography color="error">{error}</Typography>}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button onClick={handlePasswordSubmit} color="primary">
                Change Password
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>

        <Paper
          sx={{
            padding: "20px",
            marginBottom: "20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h6" sx={{ color: "#555", fontWeight: "bold" }}>
            Email Address
          </Typography>
          <Typography sx={{ color: "#777", mt: 1 }}>
            This is your email, to receive assignments and alerts about
            policies, and important system messages.
          </Typography>
          <Typography sx={{ color: "#333", fontWeight: "bold", mt: 2 }}>
            {user.primary_email}
          </Typography>
        </Paper>

        <Paper
          sx={{
            padding: "20px",
            marginBottom: "20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h6" sx={{ color: "#555", fontWeight: "bold" }}>
            Notifications
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={config.notificationsEnabled}
                onChange={handleSwitchChange("notificationsEnabled")}
                color="primary"
              />
            }
            label="App Notifications"
          />

          <FormControlLabel
            control={
              <Switch
                checked={config.emailNotifications}
                onChange={handleSwitchChange("emailNotifications")}
                color="primary"
              />
            }
            label="Email Notifications"
          />
        </Paper>

        <Paper
          sx={{
            padding: "20px",
            marginBottom: "20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h6" sx={{ color: "#555", fontWeight: "bold" }}>
            System Theme
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={config.darkMode}
                onChange={handleSwitchChange("darkMode")}
                color="primary"
              />
            }
            label="Enable Dark Mode"
          />
        </Paper>
      </Paper> 
      
    </div>
  );
};

export default Settings;
