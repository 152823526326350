import React, { useEffect, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import packageJson from "../../../package.json";
import ApiUrls from '../../Configurations/ConfigurationsApiUrls';
import {axiosInstance,useAuthHeaders} from '../../Utils/axiosInstance';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';



const PdfViewer = ({ policyId }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState("");

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  // const toolbarPluginInstance = toolbarPlugin();
  // const fullScreenPluginInstance = fullScreenPlugin();
  // const pageNavigationPluginInstance = pageNavigationPlugin();
  // const { Toolbar } = toolbarPluginInstance;


  useEffect(() => {
    if (policyId) {
      axiosInstance.get(ApiUrls.FILESTORAGE_API +"/policydocument/paths/" + policyId)
        .then((response) => {
          const lastFileUrl = response.data[response.data.length - 1]; 
          setFileUrl(lastFileUrl);
        })
        .catch((error) => {
          setError("An error occurred while fetching the policies.");
        });
    }
  }, [policyId]);

  return (
    <div style={{ height: '100vh', width: '100%', position: 'relative' }}>
    {error && <div style={{ color: 'red' }}>{error}</div>}
    
    {fileUrl ? (
        <>
        {/* <div style={{ marginBottom: '10px', borderBottom: '1px solid #ddd' }}>
          <Toolbar />
        </div> */}
        <div style={{ flex: 1 }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer 
        fileUrl={fileUrl}  
        plugins={[defaultLayoutPluginInstance]} 
        />
      </Worker>
      </div>
      </>
    ) : (
      <p style={{ textAlign: 'center', color: 'gray', marginTop: '20px' }}>
              NO Policy File found
       </p>
    )}
  </div>
  );
};

export default PdfViewer;
