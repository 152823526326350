import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Card, CardContent, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useLocation } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PdfViewer from "../../Components/PDFViewer/PdfViewer";
import ViewQuestions from "../../Components/ViewQuestions/ViewQuestions";

const PolicyDetails = () => { 
  useAuthHeaders();
  const [policy, setPolicy] = useState({});
  const [assignment, setAssignment] = useState({});
  const [questions, setQuestions] = useState([]);
  const [showSection, setShowSection] = useState("watch");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const policyId = searchParams.get("policy_id");
  const assignmentId = searchParams.get('assignment_id');
  const userId = sessionStorage.getItem('loggedinUserId');

  useEffect(() => {
    if (policyId) {
      setLoading(true);
      axiosInstance.get(ApiUrls.POLICY_API + "/" + policyId)
        .then((response) => {
          setPolicy(response.data);
          const assesmentInfo = JSON.parse(response.data.assessment_info);
          if (Array.isArray(assesmentInfo)) {
            setQuestions(assesmentInfo);
          } else {
            setQuestions([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          setError("An error occurred while fetching the policies.");
          setLoading(false);
        });
    }
    if (assignmentId) {
      setLoading(true);
      axiosInstance.get(ApiUrls.ASSIGNMENT_API + "/" + assignmentId)
        .then((response) => {
          setAssignment(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setError("An error occurred while fetching the assignment details.");
          setLoading(false);
        });
    }
  }, []);

  const toggleStar = async (assignment) => {
    try {
      if (!assignment?.is_favourite == true) {
        const requestBody1 = {
          is_favourite: true,
        };
        const response = await axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId, requestBody1);
        setAssignment((prevAssignment) => ({
          ...prevAssignment,
          is_favourite: true,
        }));
      } else {
        const requestBody2 = {
          is_favourite: false,
        };
        await axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId, requestBody2);
        setAssignment((prevAssignment) => ({
          ...prevAssignment,
          is_favourite: false,
        }));
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleConfirmComplete = () => {
    setConfirmDialogOpen(false);
    axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId,
      {
      assignment_status: "Completed",
      completed_date: new Date(),
      }
   )
   .then(()=>{
    setAssignment((prevAssignment) => ({
      ...prevAssignment,
      assignment_status: "Completed",
    }));
      setDialogMessage("Your assignment was marked completed successfully.");
      setMessageDialogOpen(true);
   })
   .catch(()=>{
      setDialogMessage("An error occurred while changing status of your assignment. Please try again.");
      setMessageDialogOpen(true);
   })
  }

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

const closeMessageDialog = () => {
    setMessageDialogOpen(false);
};

  return (
    <Box sx={{ maxWidth: "1100px", mx: "auto", mt: 4 }}>
      <Box sx={{ backgroundColor: "#374957", borderRadius: "8px", color: "white", px: 4, py: 6, textAlign: "center", position: "relative", }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}> {policy.policy_type} </Typography>
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2, fontSize: { xs: "24px", sm: "32px", md: "40px" } }} >
          {policy.policy_name}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4, fontSize: { xs: "14px", sm: "16px" } }}>
          {policy.policy_description}
        </Typography>
        <Typography variant="subtitle2" sx={{ fontSize: { xs: "12px", sm: "14px" }, opacity: 0.8, }} >
           Status :{assignment.assignment_status}
        </Typography>

        {assignmentId && (
          <Box sx={{ position: "absolute", top: "16px", right: "16px", display: "flex", alignItems: "center", }}>
            <IconButton onClick={(e) => { e.stopPropagation(); toggleStar(assignment) }}>
              {assignment.is_favourite === true ? (
                <StarIcon sx={{ color: "#fdd835" }} />
              ) : (
                <StarBorderIcon sx={{ color: "#fdd835" }} />
              )}
            </IconButton>
            {/* <IconButton sx={{ color: "white" }}>
                <MoreVertIcon />
          </IconButton> */}
          </Box>
        )}
      </Box>

      <Card sx={{ flex: 1, borderRadius: "8px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", padding: 1, display: "flex", justifyContent: "space-evenly", }}>
        <Button onClick={() => setShowSection("watch")}
          startIcon={<img src="https://img.icons8.com/ios-filled/20/000000/read.png" alt="Watch Icon" />}
          sx={{ fontWeight: "bold", color: "black", }}
        >
          Watch
          {showSection === "watch" && (
            <Box sx={{ position: "absolute", bottom: -10, left: 0, width: "100%", height: "6px", backgroundColor: "#6b9bca" }} />
          )}
        </Button>

        {policyId && assignment?.assignment_status === "Assigned" &&   (
          <>
          {assignment?.is_assessment_held === true ? (
            <Button onClick={() => setShowSection("startAssignment")}
              startIcon={<img src="https://img.icons8.com/ios-filled/20/000000/test.png" alt="Watch Icon" />}
              sx={{ fontWeight: "bold", color: "black", }}
            >
              Assessment Details
              {showSection === "startAssignment" && (
                <Box sx={{ position: "absolute", bottom: -10, left: 0, width: "100%", height: "6px", backgroundColor: "#6b9bca" }} />
              )}
            </Button>
          ) : (
              <Button
                startIcon={<img src="https://img.icons8.com/ios-filled/20/000000/start.png" alt="Watch Icon" />}
                sx={{ fontWeight: "bold", color: "black" }} onClick={() => { 
                  setConfirmDialogOpen(true); 
                  setShowSection("Mark as Completed");
                  setDialogMessage("Your assessment was completed successfully. Do you want to mark this policy as complete?");
                  }}>
                Mark as Completed
                {showSection === "Mark as Completed" && (
                  <Box sx={{ position: "absolute", bottom: -10, left: 0, width: "100%", height: "6px", backgroundColor: "#6b9bca" }} />
                )}
              </Button>
          )}
          </>
        )}
      </Card>

      <Box sx={{ mt: 4, display: "flex", alignItems: "flex-start", gap: 2 }}>
        <Card sx={{ flex: 1, borderRadius: "8px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
          <CardContent>
            {showSection === "watch" && <PdfViewer policyId={policyId} />}
            {showSection === "startAssignment" && <ViewQuestions questions={questions} loading={loading} assignmentId={assignmentId} setAssignment={setAssignment} />}
          </CardContent>
        </Card>
      </Box>

      <Dialog open={confirmDialogOpen} onClose={closeConfirmDialog}>
        <DialogTitle>Policy Completion Acknowledgement</DialogTitle>
        <DialogContent>
        <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: "#7da1b2", color: "white" }} onClick={closeConfirmDialog} >
            Cancel
          </Button>
          <Button style={{ backgroundColor: "#34425a", color: "white" }} onClick={() => handleConfirmComplete()} variant="contained" color="error" >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={messageDialogOpen} onClose={closeMessageDialog}>
                {/* <DialogTitle>Information</DialogTitle> */}
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: "#7da1b2", color: "white" }} onClick={closeMessageDialog}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
    </Box>

  );
};
export default PolicyDetails;