import React, { useState } from "react";
import { TextField, Drawer, Button, FormControl, InputLabel, Select, MenuItem, Divider, Typography, MenuItem as DropdownMenuItem } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const AdvanceFilterDrawer = ({ setFilterOpen, filterOpen }) => {
    const [department, setDepartment] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [subject, setSubject] = useState("");

    const toggleFilterDrawer = (open) => () => {
        setFilterOpen(open);
    };

    const searchOptions = [
        "Global Manual",
        "Hospital Manual",
        "Ambulatory Care",
        "Skilled Nursing Unit",
        "Long Term Acute Care",
        "Inpatient Rehabilitation Unit",
        "Home Health",
    ];

    return (
        <Drawer
            anchor="right"
            open={filterOpen}
            onClose={toggleFilterDrawer(false)}
            sx={{
                width: "300px",
                height:"500px",
            }}
        >
            <div
                style={{
                    width: "300px",
                    padding: "20px",
                    backgroundColor: "#f4f6f8",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    zIndex:1000000
                }}
            >
                <Typography variant="h6" gutterBottom style={{ fontWeight: 600 }}>
                    Advanced Filters
                    <CloseSharpIcon onClick={toggleFilterDrawer(false)} style={{ paddingLeft: '200px', paddingTop: '10px' }} />
                </Typography>

                <Divider style={{ marginBottom: "15px" }} />

                {/* Department Name */}
                <FormControl fullWidth style={{ marginBottom: "15px" }}>
                    <InputLabel>Department Name</InputLabel>
                    <Select
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                    >
                        {searchOptions.map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Sort Order */}
                <FormControl fullWidth style={{ marginBottom: "15px" }}>
                    <InputLabel>Sort Order</InputLabel>
                    <Select
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                    >
                        <MenuItem value="newToOld">New to Old</MenuItem>
                        <MenuItem value="oldToNew">Old to New</MenuItem>
                    </Select>
                </FormControl>

                {/* Date Range */}
                <div style={{ marginBottom: "15px" }}>
                    <DatePicker
                        label="From Date"
                        value={fromDate}
                        onChange={(newValue) => setFromDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </div>
                <div style={{ marginBottom: "15px" }}>
                    <DatePicker
                        label="To Date"
                        value={toDate}
                        onChange={(newValue) => setToDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </div>

                {/* Subject */}
                <TextField
                    label="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    fullWidth
                    style={{ marginBottom: "15px" }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleFilterDrawer(false)}
                    style={{
                        marginTop: "20px",
                        borderRadius: "25px",
                        padding: "10px 20px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                    }}
                >
                    Apply Filters
                </Button>
            </div>
        </Drawer>
    );
};

export default AdvanceFilterDrawer;