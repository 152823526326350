import React from "react";
import { routers, tabs } from "../../Configurations/configurationUI";
import NavItem from "../NavigationBar/NavItem";

export default function Navigation(props) {
  const [opened, setOpened] = React.useState(Array(10).fill(false));
  const [activeItems, setActiveItems] = React.useState(Array(7).fill(false));
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

  const userRole = sessionStorage.getItem("loggedinUserRole");

  const setIndex = (index, stateOpen) => {
    const newOpened = [...opened];
    newOpened[index] = stateOpen;
    setOpened(newOpened);
  };

  const handleItemClick = (index) => {
    const newActiveItems = Array(10).fill(false);
    newActiveItems[index] = true;
    setActiveItems(newActiveItems);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const styles = {
    sidebar: {
      width: isSidebarOpen ? "300px" : "25px",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px 55px",
      fontWeight: "bold",
      fontSize: "18px",
      color: "#1a1a2e",
      borderBottom: isSidebarOpen ? "1px solid #dee2e6" : 0,
      borderRight: "1px solid #dee2e6",
    },
    closeButton: {
      position: "relative",
      right: isSidebarOpen ? "-78px" : "85px",
      width: isSidebarOpen ? "40px" : "60px",
      height: isSidebarOpen ? "40px" : "60px",
      borderRadius: isSidebarOpen ? "50%" : 0,
      backgroundColor: "#ffffff",
      boxShadow: "0 0 5px rgba(0,0,0,0.2)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      border: "1px solid #dee2e6",
      zIndex: 1,
    },
    menu: {
      display: isSidebarOpen ? "flex" : "none",
      flexDirection: "column",
      padding: "15px",
      overflowY: "auto",
      maxHeight: "calc(100vh - 165px)",
    },
  };

  return (
    <div style={styles.sidebar}>
      <div style={styles.header}>
        <span>Main Menu</span>
        <div style={styles.closeButton} onClick={toggleSidebar}>
          <i
            className={`fa fa-${
              isSidebarOpen ? "chevron-left" : "chevron-right"
            }`}
          />
        </div>
      </div>
      <div style={styles.menu}>
        {/* {common section} */}
        <NavItem
          title="Home"
          mainTab={routers.home}
          icon="fa fa-home"
          tabs={{}}
          setOpenIndex={(stateOpen) => setIndex(0, stateOpen)}
          open={opened[0]}
          isSimpleNav={false}
          onClick={() => handleItemClick(0)}
          active={opened[0]}
        />
        <NavItem
          title="Browse Manuals"
          mainTab={routers.browseManuals}
          icon="fa fa-book"
          tabs={{}}
          setOpenIndex={(stateOpen) => setIndex(1, stateOpen)}
          open={opened[1]}
          isSimpleNav={false}
          onClick={() => handleItemClick(1)}
          active={opened[1]}
        />
        <NavItem
          title="favourites"
          mainTab={routers.favourites}
          icon="fa fa-heart"
          tabs={tabs.Forms}
          setOpenIndex={(stateOpen) => setIndex(2, stateOpen)}
          open={opened[2]}
          onClick={() => handleItemClick(2)}
          active={opened[2]}
        />

        {/* <NavItem
            title="Result"
            mainTab="/"
            icon="fa fa-check-circle"
            tabs={{}}
            setOpenIndex={(stateOpen) => setIndex(4, stateOpen)}
            open={opened[4]}
            isSimpleNav={false}
            onClick={() => handleItemClick(4)}
            active={opened[4]}
          /> */}
        <NavItem
          title="Competences"
          mainTab="/competencies"
          icon="fa fa-briefcase"
          tabs={{}}
          setOpenIndex={(stateOpen) => setIndex(5, stateOpen)}
          open={opened[5]}
          isSimpleNav={false}
          onClick={() => handleItemClick(5)}
          active={opened[5]}
        />

        {/* {only for Administrator} */}
        {userRole === "Administrator" && (
          <div>
            <div>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 200,
                  textAlign: "center",
                }}
              >
                {" "}
                Administration
              </span>
            </div>

            <NavItem
              title="Policy Management"
              mainTab={{}}
              icon="fa fa-bookmark"
              tabs={{
                "Policy Library": routers.policyLibrary,
                "Add Policy": routers.addPolicies,
                // "Edit Policy":routers.editPolicies,
                "Policy Types": routers.policytypes,
                "Add Policy Type": routers.addtype,
              }}
              setOpenIndex={(stateOpen) => setIndex(9, stateOpen)}
              open={opened[9]}
              isSimpleNav={false}
              onClick={() => handleItemClick(9)}
              active={opened[9]}
            />

            <NavItem
              title="Assign Competencies"
              mainTab={routers.assignCompetencies}
              icon="fa fa-podcast"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(13, stateOpen)}
              open={opened[13]}
              isSimpleNav={false}
              onClick={() => handleItemClick(13)}
              active={opened[13]}
            />

            <NavItem
              title="Reports"
              mainTab="/reports"
              icon="fa fa-chart-line"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(3, stateOpen)}
              open={opened[3]}
              onClick={() => handleItemClick(3)}
              active={opened[3]}
            />

            <NavItem
              title="Monitor Process"
              mainTab={routers.monitorProcess}
              icon="fa fa-eye"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(12, stateOpen)}
              open={opened[12]}
              isSimpleNav={false}
              onClick={() => handleItemClick(12)}
              active={opened[12]}
            />

            <NavItem
              title="User Management"
              mainTab={{}}
              icon="fa fa-users"
              tabs={{
                Users: routers.userlist,
                "Add Users": routers.adduser,
              }}
              setOpenIndex={(stateOpen) => setIndex(6, stateOpen)}
              open={opened[6]}
              isSimpleNav={false}
              onClick={() => handleItemClick(6)}
              active={opened[6]}
            />
            <NavItem
              title="Location Management"
              mainTab={{}}
              icon="fa fa-map-marker-alt"
              tabs={{
                Locations: routers.locationslist,
                "Add Locations": routers.addlocations,
              }}
              setOpenIndex={(stateOpen) => setIndex(7, stateOpen)}
              open={opened[7]}
              isSimpleNav={false}
              onClick={() => handleItemClick(7)}
              active={opened[7]}
            />
            <NavItem
              title="Department Management"
              mainTab={{}}
              icon="fa fa-building"
              tabs={{
                Departments: routers.departmentlist,
                "Add Departments": routers.adddepartment,
              }}
              setOpenIndex={(stateOpen) => setIndex(8, stateOpen)}
              open={opened[8]}
              isSimpleNav={false}
              onClick={() => handleItemClick(8)}
              active={opened[8]}
            />

            <NavItem
              title="Edit Configuration"
              mainTab={routers.editConfiguration}
              icon="fa fa-edit"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(10, stateOpen)}
              open={opened[10]}
              isSimpleNav={false}
              onClick={() => handleItemClick(10)}
              active={opened[10]}
            />
            <NavItem
              title="Stay Alert"
              mainTab={routers.stayAlert}
              icon="fa fa-exclamation-triangle"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(14, stateOpen)}
              open={opened[14]}
              isSimpleNav={false}
              onClick={() => handleItemClick(14)}
              active={opened[14]}
            />
            <NavItem
              title="Audit"
              mainTab={routers.audit}
              icon="fa fa-search-plus"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(15, stateOpen)}
              open={opened[15]}
              isSimpleNav={false}
              onClick={() => handleItemClick(15)}
              active={opened[15]}
            />
          </div>
        )}

        {/* {only for Administrator,Director, Senior Leader, Quality Director} */}
        {(userRole === "Administrator" ||
          userRole === "Director" ||
          userRole === "Senior Leader" ||
          userRole === "Quality Director") && (
          <>
            <div>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 200,
                  textAlign: "center",
                }}
              >
                {" "}
                Policy Process
              </span>
            </div>
            <NavItem
              title="Approval & Review"
              mainTab={routers.approvalReview}
              icon="fa fa-file-pdf"
              tabs={{}}
              setOpenIndex={(stateOpen) => setIndex(11, stateOpen)}
              open={opened[11]}
              isSimpleNav={false}
              onClick={() => handleItemClick(11)}
              active={opened[11]}
            />
          </>
        )}
      </div>
    </div>
  );
}
