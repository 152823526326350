import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Chip,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import UpdateIcon from "@mui/icons-material/Update";
import PolicyIcon from "@mui/icons-material/Policy";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";

const StayAlert = () => { 
  useAuthHeaders();
  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`${ApiUrls.POLICY_API}/expire/alerts`)
      .then((response) => {
        setPolicies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching policies:", error);
      });
  }, []);

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const calculateDaysDifference = (date) => {
    const today = new Date();
    const targetDate = new Date(date);
    const differenceInTime = targetDate - today; // Difference in milliseconds
    const differenceInDays = Math.round(differenceInTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
  };

  return (
    <div>
      <Typography
        variant="h4"
        sx={{ mb: 3, fontWeight: "bold", color: "#333" }}
      >
        Stay Alert - Policy & Compliance Updates
      </Typography>
      <Paper
        sx={{
          borderRadius: "15px",
          padding: "30px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <List>
          {policies.map((policy) => {
            const daysDifference = calculateDaysDifference(policy.expiration_date);
            return (
              <React.Fragment key={policy.policy_id}>
                <ListItem
                  sx={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "10px",
                    mb: 2,
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                    padding: "16px",
                  }}
                >
                  <ListItemIcon>
                    {policy.policy_status === "Expired" ? (
                      <PolicyIcon color="error" />
                    ) : (
                      <UpdateIcon color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", color: "#333" }}
                        >
                          {policy.policy_status === "Expired"
                            ? `Expired `
                            : `About to Expire `}
                        </Typography>
                        <Chip
                          label={formatDate(policy.expiration_date)}
                          size="small"
                          sx={{
                            ml: 2,
                            backgroundColor: "#e0f7fa",
                            color: "#00796b",
                            fontWeight: "bold",
                          }}
                        />
                      </Box>
                    }
                    secondary={
                      policy.policy_status === "Expired"
                        ? `${policy.policy_name} Policy has been expired ${
                            Math.abs(daysDifference)
                          } days ago.`
                        : `${policy.policy_name} Policy will expire in ${
                            Math.abs(daysDifference)
                          } days.`
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </Paper>
    </div>
  );
};

export default StayAlert;
