import React, { useState, useEffect } from "react";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { routers } from "../../Configurations/configurationUI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Paper, TextField, Button } from "@mui/material";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import { Alert } from "@mui/material"; 

const EditLocations = () => { 
  useAuthHeaders();
  const [formData, setFormData] = useState({
    location_name: "",
    city: "",
    state: "",
    country: "",
    address: "",
  });

  const [locationName, setLocationName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState(""); 
  const [status, setStatus] = useState("");

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const locationId = queryParams.get("location_id");

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.LOCATION_API + "/" + locationId)
      .then((response) => {
        const data = response.data;
        setLocationName(data.location_name || "");
        setCity(data.city || "");
        setState(data.state || "");
        setCountry(data.country || "");
        setAddress(data.address || ""); 
        setStatus(data.location_status || "");
      })
      .catch((error) => {
        // error handle
      });
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!locationName) newErrors.locationName = "Location name is required";
    if (!city) newErrors.city = "City is required";
    if (!state) newErrors.state = "State is required";
    if (!country) newErrors.country = "Country is required";
    if (!address) newErrors.address = "Address is required";
    if (!status) newErrors.status = "status is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "location_name") {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "city") {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "state") {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "country") {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "address") {
      setFormData({ ...formData, [name]: value });
    } 

    if (name === "status") {
      setStatus({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    const updatedFormData = {
      location_name: locationName,
      city: city,
      state: state,
      country: country,
      address: address, 
      location_status:status,
    };

    if (validateForm()) {
      axiosInstance
        .put(ApiUrls.LOCATION_API + "/" + locationId, updatedFormData)
        .then((response) => {
          setAlert({
            show: true,
            message: "Location  Updated successfully",
            severity: "success",
          });
          setTimeout(() => {
            
            setAlert((alert) => ({ ...alert, show: false }));
            navigate(routers.locationslist);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error Update Location",
            severity: "error",
          });
          console.error("Error Updating Location:", error);
        });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
    }
  };

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <Typography variant="h5" sx={{ mb: 8 }}>
        Edit Location
      </Typography>
      <Paper
        sx={{
          padding: "20px",
          mt: -5,
          backgroundColor: "#e3f2fd",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            height: "90%",
            maxHeight: "100%",
            overflowY: "auto",
            paddingBottom: "20px",
          }}
          className="custom-scrollbar"
        >
          <TextField
            label="Location Name"
            variant="outlined"
            name="location_name"
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
            error={!!errors.locationName}
            helperText={errors.locationName}
            style={{ marginTop: "15px" }}
          />
          <TextField
            label="City"
            variant="outlined"
            name="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            error={!!errors.city}
            helperText={errors.city}
          />
          <TextField
            label="State"
            variant="outlined"
            name="state"
            value={state}
            onChange={(e) => setState(e.target.value)}
            error={!!errors.state}
            helperText={errors.state}
          />
          <TextField
            label="Country"
            variant="outlined"
            name="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            error={!!errors.country}
            helperText={errors.country}
          />
          <TextField
            label="Address"
            variant="outlined"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            multiline
            rows={3}
            error={!!errors.address}
            helperText={errors.address}
          />

          <TextField
            label="Location Status"
            variant="outlined"
            name="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            error={!!errors.status}
            helperText={errors.status}
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ backgroundColor: "#1976d2", color: "#fff" }}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

export default EditLocations;
