import { Button,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material';
import React, { useState } from 'react';
import {axiosInstance,useAuthHeaders} from '../../Utils/axiosInstance';
import ApiUrls from '../../Configurations/ConfigurationsApiUrls';

const ViewQuestions = ({ questions, loading, assignmentId, setAssignment }) => {
    const [answers, setAnswers] = useState([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');

    const handleAnswerChange = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = value;
        setAnswers(updatedAnswers);
    };


    const handleSubmit = () => {
        const questionsAndAnswers = questions.map((q, index) => ({
            question: q.question,
            correctAnswer: Array.isArray(answers[index])
                ? answers[index].join(', ') 
                : answers[index] || '',
        }));

        const requestBody = { questionsAndAnswers };
       axiosInstance.post(ApiUrls.ASSIGNMENT_API + "/status/" + assignmentId, requestBody )
       .then((response)=>{
        if (response.data.message === "Assessment completed") {
            setDialogMessage("Your assessment was completed successfully. Do you want to mark this policy as complete?");
           
            setConfirmDialogOpen(true);
        } else if (response.data.message === "Assessment Not completed") {
            setDialogMessage("Your assessment is not completed. Please reattempt the questions.");
            setMessageDialogOpen(true);
        }
       })
       .catch(()=>{
        setDialogMessage("An error occurred while submitting your answers. Please try again.");
        setMessageDialogOpen(true);
       })
       
    };

    const closeConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };

    const closeMessageDialog = () => {
        setMessageDialogOpen(false);
    };

    const handleConfirmComplete = () => {
        setConfirmDialogOpen(false);
        axiosInstance.put(ApiUrls.ASSIGNMENT_API + "/" + assignmentId,
            {
            assignment_status: "Completed",
            completed_date: new Date(),
            assessment_taken:true,
            assessment_status:"Completed"
             }
         )
         .then(()=>{
            setAssignment((prevAssignment) => ({
                ...prevAssignment,
                assignment_status: "Completed",
              }));
            setDialogMessage("Your assignment was marked completed successfully.");
            setMessageDialogOpen(true);
            
         })
         .catch(()=>{
            setDialogMessage("An error occurred while changing status of your assignment. Please try again.");
            setMessageDialogOpen(true);
         })
    }

    if (questions.length === 0) {
        return (
            <p style={{ textAlign: 'center', color: 'gray', marginTop: '20px' }}>
                No assessment for this policy.
            </p>
        );
    }

    return (
        <div style={{ width: '95%', margin: '0 auto', padding: '20px', backgroundColor: '#f5f7f9', border: '1px solid #d0d5db', borderRadius: '8px' }}>
            {loading ? (
                <p>Loading questions...</p>
            ) : (
                <>
                    <h2 style={{ color: 'rgb(118 139 161)', alignItems: "center", justifyContent: "center" }}>Answer the Questions</h2>
                    {questions.map((q, index) => (
                        <div key={index} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
                            <h4>{q.question}</h4>
                            {/* <p><strong>Display Format:</strong> {q.displayFormat}</p> */}
                            <p> {q.questionType}</p>

                            {q.questionType === 'Multiple Choice (Only One Answer)' && (
                                <div>
                                    {q.answerChoices.map((choice, i) => (
                                        <div key={i} style={{ marginBottom: '8px' }}>
                                            <input
                                                type="radio"
                                                id={`q${index}-choice${i}`}
                                                name={`q${index}`}
                                                value={choice}
                                                checked={answers[index] === choice}
                                                onChange={() => handleAnswerChange(index, choice)}
                                            />
                                            <label htmlFor={`q${index}-choice${i}`} style={{ marginLeft: '5px' }}>{choice}</label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {q.questionType === 'Multiple Choice (Multiple Answers)' && (
                                <div>
                                    {q.answerChoices.map((choice, i) => (
                                        <div key={i} style={{ marginBottom: '8px' }}>
                                            <input
                                                type="checkbox"
                                                id={`q${index}-choice${i}`}
                                                value={choice}
                                                checked={answers[index] && answers[index].includes(choice)}
                                                onChange={(e) => {
                                                    const updatedAnswers = [...answers];
                                                    if (e.target.checked) {
                                                        updatedAnswers[index] = [...(updatedAnswers[index] || []), choice];
                                                    } else {
                                                        updatedAnswers[index] = updatedAnswers[index].filter((answer) => answer !== choice);
                                                    }
                                                    setAnswers(updatedAnswers);
                                                }}
                                            />
                                            <label htmlFor={`q${index}-choice${i}`} style={{ marginLeft: '5px' }}>{choice}</label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {q.questionType === 'Text' && (
                                <div style={{ marginTop: '10px' }}>
                                    <textarea
                                        value={answers[index] || ''}
                                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                                        placeholder="Your Answer"
                                        style={{
                                            width: '100%',
                                            height: '100px',
                                            padding: '8px',
                                            border: '1px solid #d0d5db',
                                            borderRadius: '4px',
                                        }}
                                    />
                                </div>
                            )}

                            {q.questionType === 'Rating' && (
                                <div>
                                    <label>Rating:</label>
                                    <select
                                        value={answers[index] || ''}
                                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                                        style={{ padding: '8px', borderRadius: '4px', border: '1px solid #d0d5db', width: '100px' }}
                                    >
                                        <option value="">Select Rating</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                            )}

                            {q.otherAnswerChoice && (
                                <div style={{ marginTop: '10px' }}>
                                    <input
                                        type="text"
                                        value={answers[index]?.other || ''}
                                        onChange={(e) => {
                                            const updatedAnswers = [...answers];
                                            updatedAnswers[index] = { ...updatedAnswers[index], other: e.target.value };
                                            setAnswers(updatedAnswers);
                                        }}
                                        placeholder="Other Answer (Optional)"
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #d0d5db',
                                            borderRadius: '4px',
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                     {assignmentId && (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                        <Button variant="contained" onClick={handleSubmit} color="primary">
                            Submit
                        </Button>
                    </div>
                    )}
                </>
            )}
             <Dialog open={confirmDialogOpen} onClose={closeConfirmDialog}>
                <DialogTitle>Assessment Completed</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: "#7da1b2", color: "white" }} onClick={closeConfirmDialog}>
                        Cancel
                    </Button>
                    <Button
                        style={{ backgroundColor: "#34425a", color: "white" }}
                        onClick={handleConfirmComplete}
                        variant="contained"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Message Dialog */}
            <Dialog open={messageDialogOpen} onClose={closeMessageDialog}>
                {/* <DialogTitle>Information</DialogTitle> */}
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: "#7da1b2", color: "white" }} onClick={closeMessageDialog}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default ViewQuestions;
