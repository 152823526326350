import React, { useEffect, useState } from "react";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { useNavigate } from "react-router-dom";
import { routers } from "../../Configurations/configurationUI";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import { useTheme } from "@mui/material/styles";
import ReorderIcon from "@mui/icons-material/Reorder";

const AddPolicyTypes = () => {  
  useAuthHeaders();
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    type_name: "",
    type_description: "",
    departments_involved: "",
    type_status: "Active",
  });
  const [alert, setAlert] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.DEPARTMENT_API)
      .then((response) => setDepartments(response.data))
      .catch((error) => console.error("Error fetching departments:", error));
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.type_name) newErrors.type_name = "Type name is required";
    if (!formData.type_description)
      newErrors.type_description = "Description is required";
    if (!selectedDepartments.length)
      newErrors.departments_involved = "Please choose atlest one Department";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDepartmentSelect = (event) => {
    const { value } = event.target;
    const selected = typeof value === "string" ? value.split(",") : value;
    setSelectedDepartments(selected);
    setFormData({
      ...formData,
      departments_involved: JSON.stringify(selectedDepartments),
    });
  };

  function getStyles(name, selectedDepartments, theme) {
    return {
      fontWeight: selectedDepartments.includes(name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  const handleSubmit = () => {
    if (validateForm()) {
      axiosInstance
        .post(ApiUrls.POLICY_TYPE, formData)
        .then((response) => {
          setAlert({
            show: true,
            message: "Type added successfully",
            severity: "success",
          });
          setTimeout(() => {
            setAlert((alert) => ({ ...alert, show: false }));
            navigate(routers.policytypes);
          }, 2000);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Error adding type",
            severity: "error",
          });
        });
    } else {
      setAlert({
        show: true,
        message: "Please fill out all required fields",
        severity: "warning",
      });
    }
  };

  const handleClear = () => {
    setFormData({
      type_name: "",
      type_description: "",
      departments_involved: "",
    });
    setSelectedDepartments([]);
    setErrors({});
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div>
      {alert.show && (
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, show: false })}
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "400px",
            zIndex: 1000,
          }}
        >
          {alert.message}
        </Alert>
      )}
      <div
        style={{
          backgroundColor: "#E5F2FF",
          borderRadius: "15px",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>
          <h2
            style={{ fontWeight: "bold", color: "#1D3557", fontSize: "31px" }}
          >
            Adding Type is Essential.
          </h2>
          <p
            style={{
              backgroundColor: "white",
              borderRadius: "50px",
              padding: "1rem 2rem",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              marginTop: "1rem",
              fontSize: "16px",
              lineHeight: "1.5",
            }}
          >
            To organize and classify your policies effectively, each policy
            needs a specific type. Provide the type that best represents the
            policy you are adding.
          </p>
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <img
            src="https://healthray.com/wp-content/uploads/2024/02/Hospital-Policy-Management-Software_-Categories-Advantages-and-Characteristics.webp"
            alt="Location Image"
            style={{
              width: "50%",
              height: "auto",
              borderRadius: "15px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              objectFit: "cover",
              clipPath: "polygon(19% 0%, 100% 0%, 100% 100%, 0% 100%)",
            }}
          />
        </div>
      </div>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "10px",
          gap: "18px",
          marginTop: "60px",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
          <ReorderIcon sx={{ mr: 2 }} />
          Add Type Here
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            label="Type Name"
            variant="outlined"
            name="type_name"
            value={formData.type_name}
            onChange={handleChange}
            error={!!errors.type_name}
            helperText={errors.type_name}
            sx={{ width: "48%" }}
          />

          <FormControl sx={{ width: "48%" }} error={!!errors.departments_involved}>
            <InputLabel>Departments</InputLabel>
            <Select
              label="departments"
              id="departments"
              multiple
              value={selectedDepartments}
              onChange={handleDepartmentSelect}
              input={<OutlinedInput label="Departments" />}
              MenuProps={MenuProps}
            >
              {departments.map((department) => (
                <MenuItem
                  key={department.department_id}
                  value={department.department_id}
                  style={getStyles(department, selectedDepartments, theme)}
                >
                  {department.department_name}
                </MenuItem>
              ))}
            </Select>
            {errors.departments_involved && (
              <Typography variant="caption" color="error">
                {errors.departments_involved}
              </Typography>
            )}
          </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <TextField
            label="Description"
            variant="outlined"
            name="type_description"
            multiline
            rows={4}
            value={formData.type_description}
            onChange={handleChange}
            error={!!errors.type_description}
            helperText={errors.type_description}
            sx={{ width: "100%" }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            onClick={handleClear}
            sx={{ backgroundColor: "#E5F2FF", color: "black", width: "48%" }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ backgroundColor: "#1976d2", color: "#fff", width: "48%" }}
          >
            Submit
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default AddPolicyTypes;
