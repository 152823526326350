import React, { useState } from "react";
import { routers } from "../../Configurations/configurationUI";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";
import { Box } from "@mui/material";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    general: "",
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    let valid = true;
    const newErrors = { email: "", password: "", general: "" };

    if (!email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    if (!password) {
      newErrors.password = "Password is required";
      valid = false;
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    setErrors({ email: "", password: "", general: "" });
    axios
      .post(ApiUrls.SERVICE_URL + ApiUrls.USER_API + "/authenticate", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.authenticated) {
          const retrievedEntity = response.data.retrievedEntity;
          sessionStorage.setItem(
            "loggedinUser",
            JSON.stringify(retrievedEntity)
          );
          sessionStorage.setItem(
            "loggedinUserEmail",
            retrievedEntity.primary_email
          );
          sessionStorage.setItem("loggedinUserId", retrievedEntity.user_id);
          sessionStorage.setItem("loggedinUserRole", retrievedEntity.role_name);
          sessionStorage.setItem("JWTToken", response.data.token);
          navigate(routers.home);
        } else {
          setErrors({
            ...errors,
            general: "Invalid Credentials. Please try again.",
          });
        }
      })
      .catch((error) => {
        setErrors({ ...errors, general: "Login failed. Please try again." });
      });
  };

  const handleforgotpassword = () => {
    navigate(routers.forgotpassword);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        height: "100vh",
        fontFamily: "Arial, sans-serif",
        color: "#335c67",
        backgroundColor: "white",
        padding: "20px",
        boxSizing: "border-box",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          padding: { xs: "20px", md: "50px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1 style={styles.mainHeading}>Welcome to</h1>
        <h2 style={styles.subHeading}>Policy Management Software</h2>
        <p style={styles.description}>
          A secure repository to manage policies and procedures across their
          entire lifecycle. Develop, review, approve, distribute, and track
          every policy with confidence, knowing that only one published version
          exists.
        </p>
      </Box>

      <Box
        sx={{
          flex: 1,
          padding: { xs: "20px", md: "50px" },
          backgroundColor: "#e3f2fd",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: "40px", 
          marginRight: "120PX",
          marginTop: { xs: "20px", md: "90px" }, 
          height:"70%",
          maxWidth: { xs: "100%", sm: "80%", md: "30%" },
          border: "1px solid #c1d9e4",
          boxSizing: "border-box",
        }}
      >
        <h2 style={styles.formTitle}>Policy Manager</h2>

        <div style={styles.form}>
          <div style={styles.inputContainer}>
            <input
              type="email"
              placeholder="Email"
              style={styles.inputField}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p style={styles.errorText}>{errors.email}</p>}
          </div>

          <div style={styles.inputContainer}>
            <div style={{ position: "relative", width: "100%" }}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                style={{ ...styles.inputField, paddingRight: "100px" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                style={{
                  ...styles.eyeIcon,
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                <i
                  className={`fa ${showPassword ?   "fa-eye" : "fa-eye-slash"}`}
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {errors.password && (
              <p style={styles.errorText}>{errors.password}</p>
            )}
          </div>

          <button
            type="submit"
            style={styles.loginButton}
            onClick={handleLogin}
          >
            Login
          </button>

          {errors.general && <p style={styles.errorText}>{errors.general}</p>}
        </div>

        <p style={styles.forgotPassword} onClick={handleforgotpassword}>
          Forgot Password?
        </p>
      </Box>
    </Box>
  );
};

// Styles
const styles = {
  mainHeading: {
    fontSize: "1.8em",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subHeading: {
    fontSize: "3em",
    marginBottom: "20px",
  },
  description: {
    fontSize: "1.2em",
    color: "#66858a",
  },
  formTitle: {
    fontSize: "2em",
    color: "#335c67",
    marginBottom: "20px",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
  },
  inputContainer: {
    width: "100%",
    maxWidth: "350px",
  },
  inputField: {
    width: "100%",
    padding: "15px",
    fontSize: "16px",
    borderRadius: "8px",
    border: "1px solid #ccc",
  },
  loginButton: {
    width: "90%",
    padding: "15px",
    fontSize: "18px",
    backgroundColor: "#65909a",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    maxWidth: "350px",
    // marginLeft:"30px",
  },
  forgotPassword: {
    marginTop: "15px",
    color: "#65909a",
    cursor: "pointer",
    textAlign: "center",
  },
  errorText: {
    color: "#65909a",
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "left",
    // marginTop: "6px",
    // marginBottom: "7px",
    margin: "9px",
  },
};

export default LoginPage;
