import React, { useEffect, useRef } from 'react';
import { Editor } from "@tinymce/tinymce-react";

const PolicyEditor = ({ documentContent, setDocumentContent }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current && documentContent !== editorRef.current.getContent()) {
      editorRef.current.setContent(documentContent);
    }
  }, [documentContent]);

  const handleEditorChange = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDocumentContent(content);
    }
  };

  return (
    <Editor
      apiKey='9vlf5aeuyh4rcwq3e0580d9kezt7h0tqqp5du7qfa81p8145'
      onInit={(_evt, editor) => editorRef.current = editor}
      initialValue={documentContent}
      onChange={handleEditorChange}
      init={{
        plugins: [
          'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
        ],
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
      }}
    />
  );
};

export default PolicyEditor;
