import axios from 'axios';
import ApiUrls from '../Configurations/ConfigurationsApiUrls';
import { useEffect } from 'react';

const axiosInstance = axios.create({
    baseURL: (ApiUrls.SERVICE_URL),
});

const useAuthHeaders = () => {
    useEffect(() => {
      const setAuthHeaders = () => {
        const loginUserRole = sessionStorage.getItem('loggedinUserRole');
        const userId = sessionStorage.getItem('loggedinUserId');
        const bearerToken = sessionStorage.getItem('JWTToken');
  
        // if (loginUserRole && userId && bearerToken) {
          axiosInstance.defaults.headers['loginUserRole'] = loginUserRole;
          axiosInstance.defaults.headers['loginUserId'] = userId;
          axiosInstance.defaults.headers['Authorization'] = `Bearer ${bearerToken}`;
        // }
      };
  
      setAuthHeaders();
    }, []); 
  };
  

  export { axiosInstance, useAuthHeaders };
  
