import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Stack } from "@mui/material";
import { AccessTime, CalendarMonth as CalendarMonthIcon, Map, Person, } from "@mui/icons-material";
import {axiosInstance,useAuthHeaders} from "../../Utils/axiosInstance";
import ApiUrls from "../../Configurations/ConfigurationsApiUrls";

const AuditScreen = () => {  
  useAuthHeaders();
  const [auditData, setAuditData] = useState([]);
  const [filterProps, setFilterProps] = useState({
    loginUserRole: "",
    fromActionDate: "",
    toActionDate: "",
    eventInfo: "",
    entityName: "",
    loginUsername:"",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    axiosInstance
      .get(ApiUrls.AUDIT_API)
      .then((response) => {
        setAuditData(response.data);
      })
      .catch(() => {
        setError("Error while fetching Audit data, Please try again")
      });
  }, []);

  const handleSearch = () => {
    axiosInstance.post(ApiUrls.AUDIT_API + "/filters", filterProps)
      .then((response) => {
        setAuditData(response.data);
      })
      .catch(() => {
        setError("Error while applaying filters, Please try again")
      });
  };

  const handleResetFilters = () => {
    setFilterProps({
      loginUserId: 0,
      loginUserRole: '',
      fromActionDate: '',
      toActionDate: '',
      eventInfo: '',
      entityName: '',
      loginUsername:'',
    });

    axiosInstance.get(ApiUrls.AUDIT_API)
      .then((response) => {
        setAuditData(response.data);
      })
      .catch(() => {
        setError("Error while fetching Audit data, Please try again")
      });
  };

  const highlightText = (text, keyword) => {
    if (!keyword) return text;

    const regex = new RegExp(`(${keyword})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === keyword.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const formatDate = (date) => new Date(date).toLocaleString();
  const formatTime = (date) => new Date(date).toLocaleTimeString();

  const groupEventsByDate = (data) => {
    return data.reduce((acc, event) => {
      const eventDate = formatDate(event.action_time).split(",")[0];
      if (!acc[eventDate]) {
        acc[eventDate] = [];
      }
      acc[eventDate].push(event);
      return acc;
    }, {});
  };

  const renderEventInfo = (method, event_info, entityName) => {
    const renderObjectFields = (obj) => {
      if(obj)
        return Object.keys(obj).map((key) => {
          const value = obj[key];
          return (
            <Typography variant="body2" color="textSecondary" key={key}>
              {key} - <strong>{typeof value === 'object' ? JSON.stringify(value) : value}</strong>.
            </Typography>
          );
        });
        else return null;
    };

    switch (method) {
      case "POST":
        const newFields = renderObjectFields(event_info.responseBody);
        return (
          <Box>
            <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Added New {entityName}
            </Typography>
            {newFields}
          </Box>
        );

      case "PUT":
        const changes = Object.keys(event_info.before_update || {}).map((key) => {
          const beforeValue = event_info.before_update[key];
          const afterValue = event_info.after_update[key];
          return (
            <Typography variant="body2" color="textSecondary" key={key}>
              {key} was changed from <strong>{typeof beforeValue === 'object' ? JSON.stringify(beforeValue) : beforeValue}</strong> to <strong>{typeof afterValue === 'object' ? JSON.stringify(afterValue) : afterValue}</strong>.
            </Typography>
          );
        });
        return <Box>{changes}</Box>;

      case "DELETE":
        const deletedFields = renderObjectFields(event_info.responseBody);
        return (
          <Box>
            <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Deleted {entityName}
            </Typography>
            {deletedFields}
          </Box>
        );

      default:
        return <Typography variant="body2" color="textSecondary">Event details not available.</Typography>;
    }
  };

  const groupedData = groupEventsByDate(auditData);

  return (
    <div>
      {error ? (
         <Box sx={{ color: 'red', padding: 2 ,display:"flex", justifyContent:"center" }}>
          <Typography variant="body1" color="error">{error}</Typography>
        </Box>
      ) : (
        <>
      <Typography variant="h5" sx={{ marginBottom: 3, color: "#3f6677" }}>
        Audit
      </Typography>

      {/* Filter Section */}
      <Box sx={{ marginBottom: 3 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ flexWrap: 'wrap', }}>
          <Box sx={{ width: { xs: '100%', sm: '48%', md: '20%' } }}>
            <TextField
              label="Action By"
              variant="outlined"
              size="small"
              value={filterProps.loginUsername}
              onChange={(e) => setFilterProps({ ...filterProps, loginUsername: e.target.value })}
              fullWidth
            />
          </Box>
          <Box sx={{ width: { xs: '100%', sm: '48%', md: '24%' } }}>
            <TextField
              label="Action On"
              variant="outlined"
              size="small"
              value={filterProps.entityName}
              onChange={(e) => setFilterProps({ ...filterProps, entityName: e.target.value })}
              fullWidth
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%', sm: '48%', md: '24%' } }}>
            <TextField
              label="From Date"
              type="date"
              size="small"
              sx={{ marginRight: 1 }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={filterProps.fromActionDate}
              onChange={(e) => setFilterProps({ ...filterProps, fromActionDate: e.target.value })}
            />
          </Box>
          <Box sx={{ width: { xs: '100%', sm: '48%', md: '24%' } }}>
            <TextField
              label="To Date"
              type="date"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={filterProps.toActionDate}
              onChange={(e) => setFilterProps({ ...filterProps, toActionDate: e.target.value })}
            />
          </Box>
        </Stack>

        <Stack direction={{ sm: 'row' }} spacing={2} sx={{ marginTop: 2 }} sm={{ marginTop: 2, flexWrap: 'wrap', }}>
          <Button variant="outlined" sx={{ color: '#7da1b2', }} onClick={handleResetFilters} >Reset Filter</Button>
          <Button variant="contained" sx={{ backgroundColor: '#7da1b2', }} onClick={handleSearch} >Search</Button>
        </Stack>
      </Box>

      {/* Main Content */}
      <div>
        <Box sx={{ backgroundColor: "white", }} >
        {auditData.length === 0 ? (
            <Box sx={{ padding: 2 ,display:"flex", justifyContent:"center"}}>
              <Typography variant="h6" color="textSecondary">No Data Found</Typography>
            </Box>
          ) : (
          Object.keys(groupedData).map((date, index) => (
            <div key={index}>
              <div style={{ width: "30%", backgroundColor: "rgb(186 189 190)", padding: "8px", borderRadius: "4px", textAlign: "center", color: "white", fontWeight: "bold" }}>
                {date}
              </div>
              {groupedData[date].map((section, idx) => (
                <div style={{ display: "flex", flexDirection: "row" }}>

                  {/* audit logs */}
                  <Box sx={{ width: { xs: '60%', sm: '50%', md: '30%' }, display: "flex", justifyContent: "center", padding: "8px", borderRight: "3px solid #b8b4b4", marginBottom: 1, marginTop: 1, flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center", padding: "8px", marginBottom: 1, alignSelf: "flex-start", }}>
                      <Person fontSize="small" sx={{ marginRight: "8px" }} />
                      <Typography variant="body1">{highlightText(section?.login_username, filterProps.loginUsername)}</Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", padding: "8px", marginBottom: 1, alignSelf: "flex-start", }}>
                      <AccessTime fontSize="small" sx={{ marginRight: "8px" }} />
                      <Typography variant="body2">{formatTime(section?.action_time)}</Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", padding: "8px", marginBottom: 1, alignSelf: "flex-start", }}>
                      <Map fontSize="small" sx={{ marginRight: "8px" }} />
                      <Typography variant="body3">{highlightText(section?.entityName, filterProps.entityName)}</Typography>
                    </div>
                  </Box>

                  {/* audit Content */}
                  <Box sx={{ width: { xs: '40%', sm: '50%', md: '70%' }, padding: "16px", display: "flex", alignItems: "center", boxSizing: "border-box" }}>
                    {renderEventInfo(JSON.parse(section?.event_info)?.method, JSON.parse(section?.event_info), section?.entityName)}
                  </Box>
                </div>
              ))}
            </div>
          ))
        )}
        </Box>
      </div>
      </>
      )}
    </div>
  );
};

export default AuditScreen;
